import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import SummaryApi from '../common';
import Cookies from 'js-cookie';

const StudentEnrollmentModal = ({
  isOpen,
  onClose,
  universityName,
  courseName,
  categoryName,
}) => {
  const [courses, setCourses] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  useEffect(() => {
    const fetchCoursesAndUniversities = async () => {
      try {
        const [courseResponse, universityResponse] = await Promise.all([
          fetch(SummaryApi.getActiveCourses.url, {
            method: 'GET',
            headers: {
              Authorization: 'Bearer education_consultancy_916',
              'Content-Type': 'application/json',
            },
          }),
          fetch(SummaryApi.getActiveUniversities.url, {
            method: 'GET',
            headers: {
              Authorization: 'Bearer education_consultancy_916',
              'Content-Type': 'application/json',
            },
          }),
        ]);
  
        const [coursesData, universitiesData] = await Promise.all([
          courseResponse.json(),
          universityResponse.json(),
        ]);
  
        if (courseResponse.ok && universityResponse.ok) {
          setCourses(coursesData.data || []);
          setUniversities(universitiesData.data || []);
  
          // Set default selected course and university based on props
          const defaultCourse = coursesData.data.find(course => course.course_name === courseName);
          const defaultUniversity = universitiesData.data.find(university => university.university_name === universityName);
          
          if (defaultCourse) {
            setSelectedCourse(defaultCourse);
          }
          if (defaultUniversity) {
            setSelectedUniversity(defaultUniversity);
          }
        } else {
          throw new Error('Failed to fetch courses or universities.');
        }
      } catch (error) {
        setMessage(error.message);
      }
    };
  
    // Check for student ID in cookies when modal opens
    const encodedStudentId = Cookies.get('G3T8B2WJ4L9ZK7XYA5P1');
    const studentId = encodedStudentId ? atob(encodedStudentId) : null;
  
    if (!studentId) {
      setShowLoginPrompt(true);
    } else {
      fetchCoursesAndUniversities();
    }
  }, [courseName, universityName]);
  

  const handleCourseChange = (e) => {
    const course = courses.find(c => c.course_id === e.target.value);
    setSelectedCourse(course);
  };

  const handleUniversityChange = (e) => {
    const university = universities.find(u => u.university_id === e.target.value);
    setSelectedUniversity(university);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
  
    const encodedStudentId = Cookies.get('G3T8B2WJ4L9ZK7XYA5P1');
    const studentId = encodedStudentId ? atob(encodedStudentId) : null;
  
    const enrollmentData = {
      student_id: studentId || 'Not Found',
      course_id: selectedCourse?.course_id || '',
      university_id: selectedUniversity?.university_id || '', // Add this line
      full_name: formData.name,
      mobile_number: formData.phone,
      email: formData.email,
      address: formData.address,
      city: formData.city,
    };
  
    console.log("Data being sent to backend:", enrollmentData);
  
    try {
      const response = await fetch(SummaryApi.createStudentEnroll.url, {
        method: SummaryApi.createStudentEnroll.method,
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(enrollmentData),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        alert('Successfully enrolled!'); // Show alert for successful enrollment
        setFormData({ // Clear the form
          name: '',
          email: '',
          phone: '',
          address: '',
          city: '',
        });
        setSelectedCourse(null); // Optionally reset selected course
        setSelectedUniversity(null); // Optionally reset selected university
      } else {
        throw new Error(data.message || 'Enrollment failed.');
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  if (!isOpen) return null;

  if (showLoginPrompt) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-80">
          <h2 className="text-lg font-bold mb-4">Please Log In</h2>
          <p>You need to log in to enroll in courses.</p>
          <button onClick={onClose} className="mt-4 bg-blue-500 text-white p-2 rounded">
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[1024px] relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          aria-label="Close"
        >
          <FaTimes className="text-xl" />
        </button>

        <h2 className="text-2xl font-bold mb-4">You can Enroll into your desired courses</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              id="name"
              placeholder='Enter Name'
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="mb-4">
            <input
              type="email"
              id="email"
              placeholder='Enter Email'
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="mb-4">
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder='Enter Phone Number'
              value={formData.phone}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="address"
              placeholder='Enter Address'
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="city"
              placeholder='Enter City'
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="mb-4">
            <select
              id="course"
              name="course"
              value={selectedCourse?.course_id || ''}
              onChange={handleCourseChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select a course</option>
              {courses.map(course => (
                <option key={course.course_id} value={course.course_id}>
                  {course.course_name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <select
              id="university"
              name="university"
              value={selectedUniversity?.university_id || ''}
              onChange={handleUniversityChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="">Select a university</option>
              {universities.map(university => (
                <option key={university.university_id} value={university.university_id}>
                  {university.university_name}
                </option>
              ))}
            </select>
          </div>

          <button type="submit" className="w-full bg-green-600 text-white p-2 rounded">
            {loading ? 'Enrolling...' : 'Enroll'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default StudentEnrollmentModal;
