// ScrollToTopButton.js
import React from 'react';
import { FaAngleDoubleUp } from "react-icons/fa";
import scrollTop from '../helpers/scrollTop'; // Adjust the import path

const ScrollToTopButton = () => {
  return (
    <button
      onClick={scrollTop} // Call the global function here
      className="fixed bottom-4 right-4 text-[#853372 ] hover:text-white bg-white rounded-lg p-3 shadow-lg hover:bg-[#e1cedd] transition"
      aria-label="Scroll to top"
    >
      <FaAngleDoubleUp className='text-lg'/>
    </button>
  );
};

export default ScrollToTopButton;
