import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import Logo from "../assets/logo.svg";
import { Link, useLocation } from "react-router-dom"; // Import useLocation hook
import { MdLocalPhone } from "react-icons/md";
import SummaryApi from "../common/index";

const Footer = () => {
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]); // State for categories
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Hook to get current location

  // Fetch courses and extract categories
  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.getActiveCourses.url, {
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      setCourses(responseData.data || []);

      // Extract category names from courses (assuming each course has a `category_name` property)
      const categoryList = responseData.data.map(
        (course) => course.category_name
      );
      // Remove duplicates from the category list
      const uniqueCategories = [...new Set(categoryList)];
      setCategories(uniqueCategories);
    } catch (error) {
      alert("Failed to fetch courses: " + error.message);
      setCourses([]);
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  // Scroll to top whenever location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // This will run every time location changes

  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto">
        <div className="py-8 px-4 sm:py-16">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
              {/* Logo and Description */}
              <div className="flex flex-col">
                <Link to={"/"} className="mb-4">
                  <img src={Logo} alt="Logo" className="w-36 h-auto sm:w-44" />
                </Link>
                <p className="mb-4 text-base text-left sm:text-lg">
                  You can start and finish one of these popular courses in under
                  a day - for free! Check out the list below.
                </p>
                <div className="flex items-center">
                  <span className="mr-2 text-base sm:text-lg">
                    <strong>Follow Us:</strong>
                  </span>
                  <a className="mr-4 cursor-pointer">
                    <FaFacebook />
                  </a>
                  <a href="https://www.instagram.com/uniharbour/?igsh=MWs4eTl5ZXBvMHVqbg%3D%3D" className="mr-4 cursor-pointer">
                    <FaInstagram />
                  </a>
                  <a className="cursor-pointer">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>

              {/* Company Links */}
              <div className="text-left">
                <h4 className="font-semibold mb-2 text-xl sm:text-3xl">
                  Company
                </h4>
                <ul className="text-left text-base sm:text-lg">
                  <li>
                    <Link to="/courses">
                      <span className="hover:text-gray-400 cursor-pointer">
                        Courses
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/login">
                      <span className="hover:text-gray-400 cursor-pointer">
                        Sign In
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/signup">
                      <span className="hover:text-gray-400 cursor-pointer">
                        Sign Up
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <span className="hover:text-gray-400 cursor-pointer">
                        Contact
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Categories Links */}
              <div className="text-left">
                <h4 className="font-semibold mb-2 text-xl sm:text-3xl">
                  Categories
                </h4>
                <ul className="text-left text-base sm:text-lg">
                  {categories.length > 0 ? (
                    categories.map((category, index) => (
                      <li key={index}>
                        <span className="hover:text-gray-400 cursor-pointer">
                          {category}
                        </span>
                      </li>
                    ))
                  ) : (
                    <li>Loading categories...</li>
                  )}
                </ul>
              </div>

              {/* Contact Info */}
              <div className="text-left">
                <h4 className="font-semibold mb-2 text-xl sm:text-3xl">
                  Contact Us
                </h4>
                <ul className="text-left text-base sm:text-lg">
                  <li className="flex items-baseline mb-2">
                    <MdLocalPhone className="mr-2 cursor-pointer" /> +91
                    8977533575
                  </li>
                  <li className="flex items-baseline mb-2">
                    <FaEnvelope className="mr-2 cursor-pointer" />{" "}
                    anirudhcrmhead@exxeella.com
                  </li>
                  <li className="flex items-baseline">
                    <FaMapMarkerAlt className="mr-2 w-12 cursor-pointer" />{" "}
                    15-31, DM/13, Choudhary Arcade, 14, opposite JNTU - Hitech
                    City Road, Kukatpally Housing Board Colony, Hyderabad,
                    Telangana 500072
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="bg-gray-900 py-4">
        <div className="container max-w-7xl mx-auto">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <p className="text-xs sm:text-base">
              Designed & Developed By{" "}
              <span className="font-bold">OAKYA IT SERVICES PVT LTD</span>
            </p>
            <p className="text-xs sm:text-base mt-2 sm:mt-0">
              © Copyrights 2024 OAKYA. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
