import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaRegCircleUser } from "react-icons/fa6";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Button, Layout, Menu, theme } from 'antd';
import { toast } from 'react-toastify';
import scrollTop from '../helpers/scrollTop';
import { RiDashboardFill, RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineTags, AiOutlineAppstore, AiOutlineMenu, AiOutlineClose, AiOutlineUser, AiOutlineFileSearch, AiOutlineBook } from "react-icons/ai";
import { HiOutlineBookOpen } from "react-icons/hi2";
import { MdFavoriteBorder } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { RiFileListLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import { LiaUniversitySolid } from "react-icons/lia";

const { Header, Content } = Layout;

const AdminPanel = () => {
    const user = useSelector(state => state?.user?.user);
    const navigate = useNavigate();
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const { token } = theme.useToken();

    useEffect(() => {
        // Check if the session item exists in sessionStorage
        const sessionItem = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');

        if (!sessionItem) {
            toast.error("Please login to access admin panel.")
            navigate("/admin-login");
            return;
        }
    }, [user, navigate]);

    const handleLogout = () => {
        sessionStorage.removeItem('H7Z4L9VD2XK5JW8QRYT6');
        toast.success('Logged out successfully');
        navigate('/admin-login');
    };

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleMenuClick = (key) => {
        navigate(key);
        setIsSidebarVisible(false);
    };

    const menuItems = [
        { key: 'dashboard', icon: <RiDashboardFill />, label: 'Dashboard' },
        // {
        //     key: 'universities',
        //     icon: <LiaUniversitySolid />,
        //     label: 'Universities',
        //     children: [
        //         { key: 'all-universities', icon: <AiOutlineFileSearch />, label: 'All Universities' },
        //         // { key: 'fav-universities', icon: <MdFavoriteBorder />, label: 'Favorite Universities' },
        //         // { key: 'applied-universities', icon: <AiOutlineBook />, label: 'Applied Universities' },
        //     ],
        // },
        { key: 'all-universities', icon: <AiOutlineFileSearch />, label: 'All Universities' },
        // {
        //     key: 'countries',
        //     icon: <TbWorld />,
        //     label: 'Countries',
        //     children: [
        //         { key: 'all-countries', icon: <AiOutlineTags />, label: 'All Countries' },
        //         // { key: 'country-details', icon: <RiFileListLine />, label: 'Country Details' },
        //     ],
        // },
        { key: 'all-countries', icon: <AiOutlineTags />, label: 'All Countries' },
        {
            key: 'all-courses',
            icon: <HiOutlineBookOpen  />,
            label: 'All Courses',
        },
        {
            key: 'all-categories',
            icon: <HiOutlineBookOpen  />,
            label: 'All Categories',
        },
        {
            key: 'student-management',
            icon: <GrGroup />,
            label: 'Student Management',
            children: [
                { key: 'all-students', icon: <AiOutlineFileSearch />, label: 'Registered Students' },
                { key: 'enrolled-students', icon: <AiOutlineUser />, label: 'Enrolled Students' }
            ],
        },
        // { key: 'change-password', icon: <RiLockPasswordLine />, label: 'Change Password' },
    ];

    useEffect(() => {
        if (isSidebarVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isSidebarVisible]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header
                className="site-layout-background"
                style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 16px',
                    background: '#001529',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                }}
            >
                <Button
                    type="text"
                    onClick={toggleSidebar}
                    style={{
                        fontSize: '20px',
                        color: 'white',
                        background: 'transparent',
                        border: 'none',
                        marginRight: '16px',
                    }}
                >
                    <AiOutlineMenu />
                </Button>
                <div className="header-title" style={{ color: 'white' }}>
                    <h1>Admin Panel</h1>
                </div>
                <Button
                    type="text"
                    onClick={handleLogout}
                    style={{
                        backgroundColor: '#dc2626',
                        color: 'white',
                        border: 'none',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#b91c1c'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#dc2626'}
                >
                    Logout
                </Button>
            </Header>

            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '250px',
                    height: '100vh',
                    background: '#001529',
                    color: 'white',
                    zIndex: 1000,
                    transition: 'transform 0.3s ease',
                    transform: isSidebarVisible ? 'translateX(0)' : 'translateX(-100%)',
                    overflow: 'auto',
                    paddingTop: '64px',
                }}
            >
                <Button
                    type="text"
                    onClick={toggleSidebar}
                    style={{
                        fontSize: '24px',
                        color: 'white',
                        background: 'transparent',
                        border: 'none',
                        position: 'absolute',
                        top: '16px',
                        right: '0px',
                    }}
                >
                    <AiOutlineClose />
                </Button>
                <div className='flex flex-col items-center p-4'>
                    <div className='text-3xl cursor-pointer'>
                        {user?.profilePic ? (
                            <img src={user?.profilePic} className='w-12 h-12 rounded-full' alt={user?.name} />
                        ) : (
                            <FaRegCircleUser className='text-white text-2xl' />
                        )}
                    </div>
                    <p className='capitalize text-sm font-semibold text-white mt-2'>ADMIN</p>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[]}
                    onClick={({ key }) => handleMenuClick(key)}
                    items={menuItems.map(item => ({
                        key: item.key,
                        icon: item.icon,
                        label: item.children ? (
                            <span>{item.label}</span>
                        ) : (
                            <Link to={item.key} onClick={scrollTop} style={{ display: 'block', textAlign: 'left' }}>{item.label}</Link>
                        ),
                        children: item.children ? item.children.map(child => ({
                            key: child.key,
                            icon: child.icon,
                            label: <Link to={child.key} onClick={scrollTop}>{child.label}</Link>
                        })) : null,
                    }))}
                />
            </div>

            <Layout style={{ flex: 1 }}>
                <Content
                    style={{
                        margin: '20px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: token.colorBgContainer,
                        borderRadius: token.borderRadiusLG,
                        position: 'relative',
                        filter: isSidebarVisible ? 'blur(4px)' : 'none',
                        transition: 'filter 0.3s ease',
                    }}
                >
                    <Outlet />
                </Content>
                {isSidebarVisible && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            background: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 999,
                            cursor: 'pointer',
                        }}
                        onClick={toggleSidebar}
                    />
                )}
            </Layout>
        </Layout>
    );
};

export default AdminPanel;
