import React from "react";
import client1 from "../assets/client/AbertayUniversity.png";
import client2 from "../assets/client/CanterburyChristChurchUniversity.png";
import client3 from "../assets/client/CardiffMetropolitanUniversity.png";
import client4 from "../assets/client/LiverpoolJohnMooresUniversity.png";
import client5 from "../assets/client/UCAM(UniversidadCatolicaDeMurica).png";
import client6 from "../assets/client/UniversityofGloucestershire.png";
import client7 from "../assets/client/ConcordiaUniversity.png";


const ClientArea = () => {
  const clients = [
    { image: client1, alt: "Abertay University" },
    { image: client2, alt: "Canterbury Christ Church University" },
    { image: client3, alt: "Cardiff Metropolitan University" },
    { image: client4, alt: "Liverpool John Moores University" },
    { image: client5, alt: "UCAM (Universidad Catolica de Murica)" },
    { image: client6, alt: "University of Gloucestershire" },
    { image: client7, alt: "Concordia University, ST. Paul" },
  ];

  return (
    <div className="bg-slate-100">
      <div className="py-24 max-w-7xl mx-auto">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center">
            {clients.map((client, index) => (
              <div className="w-full md:w-1/4 sm:w-1/2 p-4" key={index}>
                <div className="client-thumb text-center">
                  <img
                    src={client.image}
                    alt={client.alt}
                    className="mx-auto"
                  />
                  <p>{client.alt}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientArea;
