import React from 'react';
import banner2 from '../assets/banner/banner2.webp'
import { Link } from 'react-router-dom';
const PromotionalBanner = () => {
    return (
        <div 
            className="bg-orange-500 relative text-white p-4 md:p-8 flex items-center justify-center h-64 md:h-96"
            style={{ backgroundImage: `url(${banner2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="space-y-2 max-w-md text-center">
                <h1 className="text-xl md:text-3xl font-bold text-white">Practical Project Management from University of Glasgow</h1>
                <Link to='/contact-us'><button className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition duration-300">Find Out More</button></Link>
            </div>
        </div>
    );
};

export default PromotionalBanner;
