import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import Cookies from 'js-cookie'; // Make sure to import js-cookie if you're using it for cookies

const OTPVerification = ({ onClose, email, otp, onSuccess }) => {
    const [otpInput, setOtpInput] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setOtpInput(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const response = await fetch(SummaryApi.otpVerification.url, {
            method: SummaryApi.otpVerification.method,
            headers: {
                'Authorization': 'Bearer education_consultancy_916', 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, otp: otpInput }), // Ensure the body has the correct OTP key
        });

        const data = await response.json();
        setLoading(false);

        if (data.status) {
            toast.success(data.message);
            const encodedStudentId = btoa(data.student_id); // Encode the student_id in base64
            Cookies.set('G3T8B2WJ4L9ZK7XYA5P1', encodedStudentId, { expires: 365 });
            onSuccess(); // Navigate to home after successful verification
            onClose(); // Close the modal
        }
        
        else {
            toast.error(data.message || 'Invalid OTP. Please try again.');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-80">
                <h2 className="text-xl font-bold mb-4">Verify Your OTP</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-left font-medium text-gray-600" htmlFor="otp">
                            Enter OTP:
                        </label>
                        <input
                            type="text"
                            id="otp"
                            value={otpInput}
                            onChange={handleChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-red-200 transition"
                            placeholder="Enter the OTP"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className={`w-full bg-[#853372] text-white p-2 rounded hover:bg-[#9b2e82] transition ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Verifying...' : 'Verify OTP'}
                    </button>
                </form>
                <button
                    onClick={onClose}
                    className="mt-4 text-gray-600 underline"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default OTPVerification;
    