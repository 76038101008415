import React from 'react';
// import BannerImage1 from '../assets/banner/image.png'; 
import BannerImage1 from '../assets/banner/Ec1.webp'; 
import BannerImage2 from '../assets/banner/EC2.webp';
import BannerG from '../assets/banner/BannerG.webp'
// import BannerG from '../assets/banner/banners2.png'
import SummaryApi from '../common/index';

const Banner2 = () => {

const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target; // Get the form element
    const formData = new FormData(form); // Use FormData to gather inputs

    try {
        const response = await fetch(SummaryApi.studentmailpopup.url, {
            method: 'POST',
            body: formData, // Directly send FormData
        });

        if (response.ok) {
            alert('Form submitted successfully!');
            console.log('Form Data:', Object.fromEntries(formData)); // Log the form data
            form.reset(); // Clear the form fields
        } else {
            alert('Failed to submit the form. Please try again.');
        }
    } catch (error) {
        alert('An error occurred: ' + error.message);
    }

    // setIsOpen(false);
};



  return (
    <section className="flex flex-col sm:flex-row p-4 sm:h-[80vh] max-w-full mx-auto bg-cover bg-center"
    style={{ backgroundImage: `url(${BannerG})` }}>
      {/* Image Section */}
      <div className="w-[screen-16px] sm:w-1/2 h-full relative">
        {/* <img
          src={BannerImage2} 
          className="w-full h-full object-contain sm:hidden" 
          alt="Banner Mobile"
        />
        <img
          src={BannerImage1} 
          className="hidden sm:block w-full h-full object-contain" 
          alt="Banner"
        /> */}
      </div>

      {/* Form Section */}
      <div className="w-[screen-16px] sm:w-1/2 h-full flex items-center justify-center">
        <div className="text-white rounded-[50px] py-8 w-full max-w-lg px-6 lg:px-8 transition-shadow duration-300 bg-[#00000099]">
          <h2 className="text-xl font-bold mb-4 text-center text-slate-100">Save over <span className='text-2xl text-orange-400'>30%</span> in paid courses</h2>
          <h2 className='text-4xl font-bold'>Empower yourself from 20k+ courses</h2>
          <p className="text-center text-slate-100 my-6 font-bold">Become part of our learning community!</p>
          <form onSubmit={handleSubmit} className="text-[#A1A1A1] text-[20px]">
            <div className="mb-6">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter Name"
                required
                className="w-full px-3 py-2 border-b-[1px] border-[#ffffff] bg-transparent lg:text-xl sm:text-lg text-base focus:outline-none focus:border-[#A2A2A2] transition duration-200"
               
              />
            </div>
            <div className="mb-6">
              <input
                 type="email"
                 id="email"
                 name="email"
                 placeholder="Enter Email"
                className="w-full px-3 py-2 border-b-[1px] border-[#ffffff] bg-transparent lg:text-xl sm:text-lg text-base focus:outline-none focus:border-[#A2A2A2] transition duration-200"
                required
              />
            </div>
            <div className="mb-6">
              <input
               type="tel"
               id="contact"
               name="contact"
               placeholder="Enter Contact Number"
                className="w-full px-3 py-2 border-b-[1px] border-[#ffffff] bg-transparent lg:text-xl sm:text-lg text-base focus:outline-none focus:border-[#A2A2A2] transition duration-200"
                required
              />
            </div>
            <div className="flex justify-center mt-8">
              <div className="g-recaptcha visitcaptcha" data-sitekey="YOUR_SITE_KEY" data-callback="recaptchaCallback1"></div>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full bg-[#ED2024] text-white py-3 px-3 sm:px-4 rounded-2xl hover:bg-red-700 transition ease-in-out duration-500 text-sm sm:text-[20px]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Banner2;
