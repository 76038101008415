import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link
import CourseCard from "./CourseCard";
import SummaryApi from "../common/index";
import { encodeId } from "../helpers/idEncoder";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons

const ITEMS_PER_PAGE = 9; // Set number of items per page

const FeaturedCourses = () => {
  const [courses, setCourses] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.getActiveCourses.url, {
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
               
      setCourses(responseData.data || []);
      // console.log("Fetched courses data:", responseData.data);
    } catch (error) {
      alert("Failed to fetch courses: " + error.message);
      setCourses([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchUniversities = async () => {
    try {
      const response = await fetch(SummaryApi.getActiveUniversities.url, { 
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();

      setUniversities(responseData.data || []);
      // console.log("Fetched universities data:", responseData.data);
    } catch (error) {
      console.error("Failed to fetch universities: " + error.message);
      setUniversities([]);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchUniversities(); // Fetch universities as well
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(courses.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const selectedCourses = courses.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return; // Prevent out-of-bounds
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div className="courses-page py-12 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-center mb-8">Explore Featured Courses</h1>

        <div className="max-w-7xl mx-auto">
          {loading ? (
            <div className="text-center">Loading courses...</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {selectedCourses.map((course) => {
               const university = universities.find(u => u.university_id === course.university_id);
               const universityName = university ? university.university_name : "Unknown University"; // Fallback if not found
             
            
                return (
                  <Link
                    key={course.course_id}
                    to={`/courses/${encodeURIComponent(encodeId(course.course_id))}`}
                    className="block"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <CourseCard course={course}  universityName={universityName}
                    universityLocation={university ? university.location : ""} 
                     />
                  </Link>
                );
              })}
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center space-x-2 items-center mt-8">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`flex items-center px-2 py-3 rounded-md transition duration-300 ease-in-out ${
              currentPage === 1
                ? "opacity-50 cursor-not-allowed"
                : "bg-green-600 text-white hover:bg-green-700"
            }`}
          >
            <FaChevronLeft className="mr-2" />
          </button>

          <div className="flex space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`mx-1 px-4 py-2 rounded-md transition duration-300 ease-in-out ${
                  currentPage === index + 1
                    ? "bg-green-600 text-white"
                    : "bg-white text-green-600 border border-green-600"
                } hover:bg-green-600 hover:text-white`}
              >
                {index + 1}
              </button>
            ))}
          </div>

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`flex items-center px-2 py-3 rounded-md transition duration-300 ease-in-out ${
              currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : "bg-green-600 text-white hover:bg-green-700"
            }`}
          >
            <FaChevronRight className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCourses;
