import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import a from "../assets/testimonial/t1.png";
import b from "../assets/testimonial/t2.png";
import c from "../assets/testimonial/t3.png";
import d from "../assets/testimonial/t4.png";
import e from "../assets/testimonial/t5.png";
import i from "../assets/icon/4.png";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      image: a,
      name: "Indraja",
      role: "Student Language",
      text: "I found myself working in a true partnership that results in an incredible experience, and an end product that is the best.",
    },
    {
      image: b,
      name: "md Arshad",
      role: "Student Language",
      text: "I'm really happy with my experience in the Uni Harbour Online MBA program. It's been valuable for my career, providing both knowledge and new job opportunities. The instructors are quick to help with any questions, making learning smooth and enjoyable.",
    },
    {
      image: c,
      name: "Ramsani Bharath Reddy",
      role: "Student Language",
      text: "I'm extremely satisfied with my experience in the Uni Harbour Online MBA program. The knowledge I'm acquiring is highly relevant to my career, and the instructors are always quick to clear up any questions or doubts I have.",
    },
    {
      image: d,
      name: "R.Pavan kumar",
      role: "Student Language",
      text: "The Uni Harbour  online MBA program has been highly beneficial for my career. It has opened up more job opportunities and provided valuable skills that are applicable in the workplace. I'm very happy with this online MBA program.",
    },
    {
      image: e,
      name: "Venkata sai kumar",
      role: "Student Language",
      text: "I wanted to share my appreciation for the  Uni Harbour online MBA program. It has been very helpful in enhancing my understanding of global business. The course materials and insights have been valuable, especially in expanding my knowledge of international business practices.",
    },
  ];

  return (
    <div className="bg-fuchsia-100">
      <div className="p-8 max-w-7xl mx-auto">
        <h2 className="text-2xl font-bold text-center mb-6">
          What people say about us
        </h2>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div className="flex items-center justify-center p-4" key={index}>
              <div className="flex flex-col items-center justify-center p-4 w-full m-2">
                <div className="mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="object-cover mb-2 rounded-lg"
                  />
                </div>
                <img src={i} alt="icon" className="mb-2" />
                <p class="text-lg italic text-gray-600 mb-2 text-justify">
                  "{testimonial.text}"
                </p>

                <p className="text-lg font-semibold text-gray-800 mb-1">
                  {testimonial.name}
                </p>
                <p className="text-sm text-gray-600">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;
