// src/PopupForm.js
import React, { useState, useEffect } from "react";
import popup from "../assets/banner/Background.png"; // Assuming the path is correct
import SummaryApi from '../common/index';

const PopupForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Show the popup immediately when the page loads
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 2000); // Delay for 2 seconds
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target); // Use FormData to gather inputs

    try {
        const response = await fetch(SummaryApi.studentmailpopup.url, {
            method: 'POST',
            body: formData, // Directly send FormData
        });

        if (response.ok) {
            alert('Form submitted successfully!');
            console.log('Form Data:', Object.fromEntries(formData)); // Log the form data
        } else {
            alert('Failed to submit the form. Please try again.');
        }
    } catch (error) {
        alert('An error occurred: ' + error.message);
    }

    setIsOpen(false);
};


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col md:flex-row relative z-60 max-w-[1040px] w-full">
        <img
          src={popup}
          alt="Study Abroad"
          className="w-full md:w-1/2 h-full object-cover rounded-lg md:ml-4 mb-4 md:mb-0"
        />
        <div className="md:w-1/2">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            onClick={handleClose}
          >
            &times;
          </button>
          <h2 className="text-4xl mb-4 text-[#853372] font-bold">
            Start your study abroad journey now.
          </h2>
          <p className="mb-4 text-gray-500">Let's get you started</p>

          
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter Name"
              required
              className="border border-gray-300 rounded p-2 mb-4 w-full"
            />
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter Email"
              required
              className="border border-gray-300 rounded p-2 mb-4 w-full"
            />
            <input
              type="tel"
              id="contact"
              name="contact"
              placeholder="Enter Contact Number"
              required
              className="border border-gray-300 rounded p-2 mb-4 w-full"
            />
            <input
              type="text"
              id="city"
              name="city"
              placeholder="Enter City"
              required
              className="border border-gray-300 rounded p-2 mb-4 w-full"
            />
            <input
              type="text"
              id="country"
              name="country"
              placeholder="Country Looking for"
              required
              className="border border-gray-300 rounded p-2 mb-4 w-full"
            />
            <input
              type="submit"
              value="Send"
              className="bg-[#853372] text-white rounded p-2 hover:bg-[#853372]/80 w-full"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupForm;
