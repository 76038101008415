// components/FeaturedCourses.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; 
import CourseCard from "./CourseCard"; 
import SummaryApi from "../common";
import scrollTop from "../helpers/scrollTop"; 
import { encodeId } from "../helpers/idEncoder"; 

const RelatedCourses = ({ categoryId, currentCourseId }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [universities, setUniversities] = useState([]);

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.getActiveCourses.url, {
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      setCourses(responseData.data || []);
      // console.log("Fetched courses data:", responseData.data);
    } catch (error) {
      alert("Failed to fetch courses: " + error.message);
      setCourses([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchUniversities = async () => {
    try {
      const response = await fetch(SummaryApi.getActiveUniversities.url, { 
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      setUniversities(responseData.data || []);
      // console.log("Fetched universities data:", responseData.data);
    } catch (error) {
      console.error("Failed to fetch universities: " + error.message);
      setUniversities([]);
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchUniversities();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>;
  }

  // Combine courses with their respective university names
  const enrichedCourses = courses.map(course => {
    const university = universities.find(u => u.university_id === course.university_id);
    return {
      ...course,
      university_name: university ? university.university_name : "Unknown University"
    };
  });

  // Filter courses based on the categoryId prop and exclude the current course
  const filteredCourses = enrichedCourses
    .filter(course => course.category_id === categoryId && course.course_id !== currentCourseId)
    .slice(0, 3);

  // console.log("Filtered Courses", filteredCourses);
  
  return (
    <div className="courses-page bg-white">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-2xl py-4 font-bold text-gray-800">
          Related Courses
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredCourses.length > 0 ? (
            filteredCourses.map((course) => (
              <Link
                key={course.course_id}
                to={`/courses/${encodeURIComponent(encodeId(course.course_id))}`}
                className="block"
                onClick={scrollTop}
              >
                <CourseCard course={course} universityName={course.university_name} /> {/* Pass university name */}
              </Link>
            ))
          ) : (
            <div className="text-center">No courses available for this category.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RelatedCourses;
