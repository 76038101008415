import React, { useState } from "react";
import PageTitle from "./PageTitle"; // Assuming PageTitle is a custom component
import b from "../assets/bg/5.png"; // Replace with your image
import SummaryApi from "../common/index";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false); // Manage submission state
  const [formStatus, setFormStatus] = useState(null); // Manage form submission status

  // Form submission handler
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(event.target);

    try {
      // Replace with your actual form submission API logic here
      const response = await fetch(SummaryApi.studentmailpopup.url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        // Show success toast
        // toast.success("Message sent successfully!");
        event.target.reset();
        setFormStatus("success");
      } else {
        // Show error toast
        toast.error("Oops, something went wrong. Please try again.");
        setFormStatus("error");
      }
    } catch (error) {
      // Show error toast for network or other issues
      toast.error("Oops, something went wrong. Please try again.");
      setFormStatus("error");
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      {/* Page Title */}
      <div>
        <PageTitle title="Have a look Our Contact Here" pageName="Contact" />
      </div>

      {/* Main Section: Contact Form and Image */}
      <div className="bg-gray-100 pt-20 pb-20 flex items-center justify-center">
        <div className="flex flex-col items-center p-6">
          <h2 className="text-3xl font-bold mb-6">Get in touch</h2>
          <div className="flex flex-col md:flex-row justify-center items-stretch gap-6">
            {/* Image Section */}
            <div className="flex-1 md:w-1/2 mb-4 md:mb-0 flex items-center">
              <img
                src={b}
                alt="Contact"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>

            {/* Contact Form Section */}
            <div className="flex-1 md:w-1/2 bg-white shadow-lg rounded-lg p-6 flex flex-col">
              <form onSubmit={handleSubmit} className="flex-grow">
                {/* First Name Input */}
                <div className="mb-4">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    className="w-full p-2 border rounded-md"
                    required
                  />
                </div>

                {/* Last Name Input */}
                <div className="mb-4">
                  <input
                    type="tel"
                    id="contact"
                    name="contact"
                    placeholder="Enter Contact Number"
                    className="w-full p-2 border rounded-md"
                    required
                  />
                </div>

                {/* Email Input */}
                <div className="mb-4">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="w-full p-2 border rounded-md"
                    required
                  />
                </div>

                {/* Message Textarea */}
                <div className="mb-4">
                  <textarea
                    placeholder="Your message here..."
                    type="text"
                    id="country"
                    name="country"
                    className="w-full p-2 border rounded-md"
                    rows="4"
                    required
                  ></textarea>
                </div>

                {/* Form Status Messages */}
                {/* <div className="mb-4">
                  {formStatus === "success" && (
                    <div className="text-green-500">
                      Message sent successfully!
                    </div>
                  )}
                  {formStatus === "error" && (
                    <div className="text-red-500">
                      Oops, something went wrong. Please try again.
                    </div>
                  )}
                </div> */}

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`bg-teal-500 text-white py-2 px-4 rounded-md hover:bg-teal-600 w-full ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Google Map Section */}
      <div className="">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.2465516589677!2d78.39239777516742!3d17.495740183410067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9194b57e29cf%3A0xb2ac3036b63ffe24!2sExxeella%20Education%20Group%20-%20Study%20Abroad%20%26%20Immigration%20%7C%20KPHB!5e0!3m2!1sen!2sin!4v1730790776124!5m2!1sen!2sin"
          className="w-full h-64 md:h-96 rounded-md shadow-lg"
          title="Google Map"
          style={{ border: 0 }} // To remove default iframe border
          allowFullScreen
        ></iframe>

      </div>
    </div>
  );
};

export default ContactUs;
