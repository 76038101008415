import React from "react";
import SummaryApi from "../common";

const CourseCard = ({ course, universityName, universityLocation, onEdit }) => {
  const imageUrl = SummaryApi.courseActiveImg.url + course.course_image1;

  return (
    <div className="border border-gray-300 rounded-lg w-full h-full overflow-hidden shadow-md flex flex-col">
      <img
        alt={course.course_name}
        src={imageUrl}
        className="h-56 w-full object-cover"
      />
      <div className="flex flex-col justify-between text-left p-4 flex-grow">
        <h3 className="mb-2 text-xl text-left font-bold">
          {course.course_name}
        </h3>
        <p>
          <strong>University:</strong> {universityName}
        </p>
        {/* Uncomment to show university location if available */}
        {/* {universityLocation && (
          <p className="text-sm mt-1 mb-2">
            <strong>Location:</strong> {universityLocation}
          </p>
        )} */}
        <p className="mt-2">
          <strong>Duration:</strong> {course.duration}
        </p>
        <p className="line-clamp-3 text-sm mt-2">
          <strong className="text-base">Description:</strong>{" "}
          {course.description}
        </p>
        <hr className="mt-2" />

        {/* Pricing section */}
        <div className="flex justify-between items-center mt-4">
          <p className="text-sm line-through text-gray-500">
            ${course.original_cost}
          </p>
          <p className="text-lg font-bold text-green-500">
            ${course.offered_cost}
          </p>
        </div>

        {/* Uncomment if you need the edit button */}
        {/* <div className="flex justify-end mt-4">
          <button 
            onClick={() => onEdit(course)} 
            className='bg-blue-500 text-white rounded px-4 py-2'
          >
            Edit
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default CourseCard;
