import React from 'react';
import AboutImage from '../assets/about/1.png';
import Icon1 from '../assets/icon/1.png';
import Icon2 from '../assets/icon/2.png';
import Icon3 from '../assets/icon/3.png';
import BlockImage1 from '../assets/other/1.png';
import BlockImage2 from '../assets/other/2.png';

const CounterArea = () => {
    return (
        <div className="py-20 bg-gray-100">
            <div className="container mx-auto px-4 max-w-7xl">
                <div className="flex flex-col lg:flex-row">
                    {/* About Image Section */}
                    <div className="w-2/3 mx-auto lg:w-1/4 bg-[#19bca5] rounded-tl-3xl rounded-tr-3xl rounded-3xl overflow-hidden flex flex-col">
                        <div className="flex-grow flex flex-col justify-between h-full">
                            <div className="thumb text-right">
                                <img src={AboutImage} alt="img" className="w-full h-full object-cover" />
                            </div>
                            <div>
                                <h4 className="text-2xl text-white font-bold pb-5 md:py-9 px-2">
                                    Access 150+ Online Courses from 20 Institutions
                                </h4>
                            </div>
                        </div>
                    </div>

                    {/* Content Section */}
                    <div className="lg:w-3/4 w-full flex flex-col h-full p-4">
                        <div className="flex flex-wrap flex-grow">
                            <div className="md:w-1/3 w-full p-4">
                                <div className="p-6 flex items-center">
                                    <div className="flex">
                                        <div className="mr-4">
                                            <div>
                                                <img src={Icon1} alt="img" className="w-10" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-5xl font-bold"><span>10</span>K+</h2>
                                            <p className='text-lg mt-1 text-gray-600'>Online Learners</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="md:w-1/3 w-full p-4">
                                <div className="p-6 flex items-center">
                                    <div className="flex">
                                        <div className="mr-4">
                                            <div>
                                                <img src={Icon2} alt="img" className="w-10" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-5xl font-bold"><span>5</span>K+</h2>
                                            <p className='text-lg mt-1 text-gray-600'>Earned Certificate</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="md:w-1/3 w-full p-4">
                                <div className="p-6 flex items-center">
                                    <div className="flex">
                                        <div className="mr-4">
                                            <div>
                                                <img src={Icon3} alt="img" className="w-10" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <h2 className="text-5xl font-bold"><span>3</span>K+</h2>
                                            <p className='text-lg mt-1 text-gray-600'>Career Benefited</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex-grow">
                            <div className="flex flex-wrap">
                                <div className="md:w-1/2 w-full px-4 py-2">
                                    <div className="relative rounded-3xl overflow-hidden">
                                        <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                        <div
                                            className="bg-cover bg-center h-64 flex flex-col justify-evenly p-4"
                                            style={{ backgroundImage: `url(${BlockImage1})`, zIndex: 5 }}
                                        >
                                            <p className="text-white flex items-start text-sm p-1 z-20">
                                                <span className='bg-orange-500 px-3 rounded-md'>Most Recent</span>
                                            </p>
                                            <div className='z-20'>
                                                <span className="flex text-white text-xl">SCHOOL OF</span>
                                                <span className="flex text-white text-xl font-bold">Programming</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="md:w-1/2 w-full px-4 py-2">
                                    <div className="relative rounded-3xl overflow-hidden">
                                        <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                        <div
                                            className="bg-cover bg-center h-64 flex flex-col justify-evenly p-4"
                                            style={{ backgroundImage: `url(${BlockImage2})`, zIndex: 5 }}
                                        >
                                            <p className="text-white flex items-start text-sm p-1 z-20">
                                                <span className='bg-orange-500 px-3 rounded-md'>Most Recent</span>
                                            </p>
                                            <div className='z-20'>
                                                <span className="flex text-white text-xl">SCHOOL OF</span>
                                                <span className="flex text-white text-xl font-bold">Data Science</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterArea;
