import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { BsPersonCircle, BsEnvelope } from "react-icons/bs";
import { RiAdminLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminLogin = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({
        username: "",
        password: ""
    });
    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Static credentials
        const adminCredentials = {
            username: "Admin",
            password: "Admin"
        };

        // Check against static data
        if (data.username === adminCredentials.username && data.password === adminCredentials.password) {
            toast.success('Login successful!');
            sessionStorage.setItem('H7Z4L9VD2XK5JW8QRYT6', btoa(data.username)); // Example, replace with actual admin id
            navigate('/admin-panel/dashboard');
        } else {
            toast.error('Invalid username or password');
        }
    };

    return (
        <div>
            {/* Header Section */}
            <header className='bg-[#001529] fixed w-full text-white p-4 text-center shadow'>
                <div className='flex items-center justify-center mb-2'>
                    <RiAdminLine className='text-3xl mr-2' />
                    <h1 className='text-xl font-bold'>Admin Panel</h1>
                </div>
            </header>

            {/* Login Section */}
            <section id='login' className='flex items-center justify-center min-h-screen bg-gray-100 pt-16'>
                <div className='mx-auto container p-4'>
                    <div className='bg-white shadow-lg rounded-lg p-6 w-full max-w-sm mx-auto'>
                        <div className='w-20 h-20 mx-auto'>
                            <BsPersonCircle className='w-20 h-20 text-gray-600' />
                        </div>
                        <h1 className='text-center font-bold text-xl'>Admin Login</h1>

                        <form className='pt-6 flex flex-col gap-4' onSubmit={handleSubmit}>
                            {/* Username Field */}
                            <div className='flex items-center bg-gray-200 p-2 rounded'>
                                <BsEnvelope className='text-gray-500 mr-2' />
                                <input
                                    type='text'
                                    placeholder='Enter username'
                                    name='username'
                                    value={data.username}
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent'
                                />
                            </div>

                            {/* Password Field */}
                            <div className='flex items-center bg-gray-200 p-2 rounded'>
                                <FaEyeSlash className='text-gray-500 mr-2' />
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder='Enter password'
                                    value={data.password}
                                    name='password'
                                    onChange={handleOnChange}
                                    className='w-full h-full outline-none bg-transparent'
                                />
                                <div className='cursor-pointer text-xl' onClick={() => setShowPassword(prev => !prev)}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>

                            {/* Forgot Password Link */}
                            {/* <Link to={'/forgot-password'} className='block w-fit ml-auto text-sm text-blue-600 hover:underline'>
                                Forgot password?
                            </Link> */}

                            <button className='bg-[#001529] hover:bg-[#536679] text-white px-6 py-2 rounded-full transition-all mx-auto block mt-6'>
                                Login
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AdminLogin;
