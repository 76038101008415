import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.svg';
import Cookies from 'js-cookie';
import { FaSearch, FaTimes, FaBars } from 'react-icons/fa';
import scrollTop from '../helpers/scrollTop';
import SummaryApi from '../common';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [studentName, setStudentName] = useState(null);
  const [studentImage, setStudentImage] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const encodedStudentId = Cookies.get('G3T8B2WJ4L9ZK7XYA5P1');
  const studentId = encodedStudentId ? atob(encodedStudentId) : null;

  useEffect(() => {
    const fetchStudentDetails = async () => {
      // console.log(studentId)
      if (studentId) {
        try {
          const response = await fetch(SummaryApi.getStudentDetails.url, {
            method: SummaryApi.getStudentDetails.method,
            headers: {
              'Authorization': 'Bearer education_consultancy_916',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ student_id: studentId }),
          });

          if (!response.ok) {
            throw new Error('Failed to fetch student details');
          }

          const responseData = await response.json();
          setStudentName(responseData?.data?.full_name);
          setStudentImage(responseData?.data?.student_image);
        } catch (error) {
          console.error('Error fetching student details:', error);
        }
      }
    };

    fetchStudentDetails();
  }, [studentId]);

  const handleLogout = () => {
    Cookies.remove('G3T8B2WJ4L9ZK7XYA5P1');
    setStudentName(null);
    setStudentImage(null);
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <header className="sticky top-0 w-full flex items-center justify-between p-4 bg-gradient-to-r from-white to-[#ceaad1] z-50 shadow-md">
      <div className="flex-shrink-0">
        <Link to="/" onClick={scrollTop}>
          <img src={Logo} alt="Logo" className='w-28' />
        </Link>
      </div>
      <nav className="hidden md:flex flex-grow justify-center items-center space-x-6 font-bold text-lg">
        <Link to="/" onClick={scrollTop} className="hover:text-gray-900 text-gray-700 text-base lg:text-xl">Home</Link>
        <Link to="/about-us" onClick={scrollTop} className="hover:text-gray-900 text-gray-700 text-base lg:text-xl">About Us</Link>
        <Link to="/courses" onClick={scrollTop} className="hover:text-gray-900 text-gray-700 text-base lg:text-xl">Courses</Link>
        <Link to="/contact-us" onClick={scrollTop} className="hover:text-gray-900 text-gray-700 text-base lg:text-xl">Contact Us</Link>
        {/* <div className="relative flex items-center">
          <input
            type="text"
            placeholder="Search Courses..."
            className="p-2 border text-base w-40 lg:w-full font-semibold border-gray-300 rounded"
          />
          <FaSearch className="absolute right-3 w-4 h-4 text-gray-500" />
        </div> */}
      </nav>
      <div className="hidden md:flex items-center space-x-4 text-lg">
        {studentName ? (
          <div 
            className="relative flex items-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div 
              className="flex items-center space-x-2 cursor-pointer"
              onClick={toggleDropdown}
            >
              <img 
                src={SummaryApi.studentImg.url + studentImage || 'default-image-url'}
                alt="Profile"
                className="w-10 h-10 rounded-full border-2 border-gray-300 shadow-md"
              />
              <span className="font-bold hidden lg:flex text-gray-800 text-base lg:text-xl">{studentName}</span>
            </div>
            {dropdownOpen && (
              <div className="absolute top-8 right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                <Link to="/profile" className="block px-4 py-2 text-left text-xs lg:text-sm text-gray-700 hover:bg-gray-100">Profile</Link>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-xs lg:text-sm text-gray-700 hover:bg-red-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <Link to={'login'} className="font-bold px-4 py-2 rounded cursor-pointer bg-blue-500 text-white hover:bg-blue-600">Login</Link>
        )}
      </div>
      <button className="md:hidden p-2" onClick={toggleMenu}>
        <FaBars className="text-xl" />
      </button>

      <div className={`md:hidden fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ${menuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={toggleMenu}>
        <div className={`fixed right-0 top-0 w-3/4 md:w-1/2 h-full bg-white shadow-lg p-4 z-50 transform transition-transform duration-300 ${menuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
          <div className="flex justify-between items-center mb-4">
            <div className="flex-shrink-0">
              <img src={Logo} alt="Logo" className='w-28' />
            </div>
            <button onClick={toggleMenu} className="text-xl">
              <FaTimes />
            </button>
          </div>
          <nav className="mt-4 text-left">
            <Link to="/" onClick={scrollTop} className="block py-3 border-b border-gray-300 font-semibold hover:text-green-500">Home</Link>
            <Link to="/about-us" onClick={scrollTop} className="block py-3 border-b border-gray-300 font-semibold hover:text-green-500">About Us</Link>
            <Link to="/courses" onClick={scrollTop} className="block py-3 border-b border-gray-300 font-semibold hover:text-green-500">Courses</Link>
            <Link to="/contact-us" onClick={scrollTop} className="block py-3 border-b border-gray-300 font-semibold hover:text-green-500">Contact Us</Link>

            {studentName ? (
              <button
                onClick={handleLogout}
                className="block w-full text-left py-3 border-b border-gray-300 font-semibold hover:text-red-500"
              >
                Logout
              </button>
            ) : (
              <Link to={'login'} className="block py-3 border-b border-gray-300 font-semibold hover:text-green-500">Login</Link>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
