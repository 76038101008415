import React from 'react';
import Icon1 from '../assets/icon/1.png';
import Icon2 from '../assets/icon/2.png';
import Icon3 from '../assets/icon/3.png';
import Icon4 from '../assets/icon/4.png';

const IconRow = () => {
  const icons = [
    { count: "10", label: "Online Learners", icon: Icon1 },
    { count: "5", label: "Earned Certificates", icon: Icon2 },
    { count: "3", label: "Career Benefited", icon: Icon3 },
    { count: "5", label: "Conferences Arranged", icon: Icon4 },
  ];

  return (
    <div className="flex flex-wrap justify-center max-w-7xl mx-auto p-4">
      {icons.map((item, index) => (
        <div key={index} className="flex justify-center items-center w-full sm:w-1/2 md:w-1/4 p-4">
          <img src={item.icon} alt={item.label} className="w-10 h-10 mr-4" />
          <div>
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold">
              <span>{item.count}</span>K+
            </h2>
            <p className='text-base sm:text-lg mt-1 text-gray-600'>{item.label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IconRow;
