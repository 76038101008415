// UniversityUser.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import SummaryApi from '../common/index';
import UniversityCardUser from './UniversityCardUser';

const UniversityUser = () => {
    const [universities, setUniversities] = useState([]);

    const fetchUniversities = async () => {
        try {
            const response = await fetch(SummaryApi.getActiveUniversities.url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                    'Content-Type': 'application/json',
                },
            });
            const responseData = await response.json();
            // console.log("Fetched universities data:", responseData);
            setUniversities(responseData.data || []);
        } catch (error) {
            alert('Failed to fetch universities');
            setUniversities([]);
        }
    };

    useEffect(() => {
        fetchUniversities();
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '40px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '20px',
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '20px',
                },
            },
        ],
    };

    return (
        <div className="p-5 mb-5">
            <div className='flex max-w-7xl mx-auto justify-between items-center mb-5'>
            <h2 className="text-3xl font-bold text-center mb-8">All Universities</h2>
            </div>
            <div className='max-w-7xl mx-auto'>
            <Slider {...settings}>
                {universities.map((university) => {
                    // console.log("Sending university data to card:", university);
                    return (
                        <div className="flex justify-center" key={university.university_id}>
                            <div className="mx-2">
                                <UniversityCardUser university={university} />
                            </div>
                        </div>
                    );
                })}
            </Slider>
            </div>
        </div>
    );
};

export default UniversityUser;
