import React, { useState, useEffect } from "react";
import CourseCard from "./CourseCard";
import CourseModal from "../components/CourseModal";
import SummaryApi from "../common/index";

const AllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.getAllCourses.url, {
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      setCourses(responseData.data || []);
    } catch (error) {
      alert("Failed to fetch courses: " + error.message);
      setCourses([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const showUploadModal = () => {
    setIsEditMode(false);
    setEditingCourse(null);
    setIsModalVisible(true);
  };

  const showEditModal = (course) => {
    setIsEditMode(true);
    setEditingCourse(course);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="p-5">
      <div className="flex max-w-7xl mx-auto justify-between items-center mb-5">
        <h2 className="text-2xl font-bold">All Courses</h2>
        <button
          onClick={showUploadModal}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Upload Course
        </button>
      </div>
      <div className="max-w-7xl mx-auto">
        {loading ? (
          <div className="text-center">Loading courses...</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {courses.map((course) => (
              <div className="flex justify-center" key={course.course_id}>
                <CourseCard
                  course={course}
                  onEdit={() => showEditModal(course)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {isModalVisible && (
        <CourseModal
          visible={isModalVisible}
          onCancel={handleCancel}
          isEditMode={isEditMode}
          editingCourse={editingCourse}
          fetchCourses={fetchCourses}
        />
      )}
    </div>
  );
};

export default AllCourses;
