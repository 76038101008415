import React, { useEffect, useState } from 'react';
import SummaryApi from '../common'; // Ensure this imports your API configuration
import { toast } from 'react-toastify';
import { FaCogs, FaUserGraduate, FaGlobe, FaUniversity, FaBookOpen } from 'react-icons/fa';

const Dashboard = () => {
  const [counts, setCounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(SummaryApi.dashboardCounts.url, {
          method: SummaryApi.dashboardCounts.method,
          headers: {
            'Authorization': 'Bearer education_consultancy_916',
          },
        });
        const data = await response.json();
        if (data.status) {
          setCounts(data.data);
        } else {
          toast.error('Failed to fetch dashboard counts.');
        }
      } catch (error) {
        console.error('Error fetching dashboard counts:', error);
        setError('Error fetching counts');
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6 text-left">Dashboard</h1>
      <div className="bg-white p-4 md:p-6 border rounded-lg shadow-lg mb-6">
        <h2 className="text-lg md:text-xl text-left font-semibold mb-4 text-gray-700">Summary</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-blue-50 p-4 rounded-lg text-center shadow-md">
            <FaCogs className="text-blue-500 text-3xl mb-2 mx-auto" />
            <h3 className="text-lg font-medium mb-2 text-blue-600">Categories</h3>
            <p className="text-xl font-bold text-blue-700">{counts.categories}</p>
          </div>
          <div className="bg-green-50 p-4 rounded-lg text-center shadow-md">
            <FaBookOpen className="text-green-500 text-3xl mb-2 mx-auto" />
            <h3 className="text-lg font-medium mb-2 text-green-600">Courses</h3>
            <p className="text-xl font-bold text-green-700">{counts.courses}</p>
          </div>
          <div className="bg-yellow-50 p-4 rounded-lg text-center shadow-md">
            <FaGlobe className="text-yellow-500 text-3xl mb-2 mx-auto" />
            <h3 className="text-lg font-medium mb-2 text-yellow-600">Countries</h3>
            <p className="text-xl font-bold text-yellow-700">{counts.countries}</p>
          </div>
          <div className="bg-purple-50 p-4 rounded-lg text-center shadow-md">
            <FaUserGraduate className="text-purple-500 text-3xl mb-2 mx-auto" />
            <h3 className="text-lg font-medium mb-2 text-purple-600">Students</h3>
            <p className="text-xl font-bold text-purple-700">{counts.students}</p>
          </div>
          <div className="bg-red-50 p-4 rounded-lg text-center shadow-md">
            <FaUniversity className="text-red-500 text-3xl mb-2 mx-auto" />
            <h3 className="text-lg font-medium mb-2 text-red-600">Universities</h3>
            <p className="text-xl font-bold text-red-700">{counts.universities}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
 