// components/FeaturedCourses.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // If you want to link to course details later
import CourseCard from "./CourseCard"; // Assuming you have a CourseCard component
import SummaryApi from "../common";
import scrollTop from "../helpers/scrollTop"; // Assuming you have a scrollTop function
import { encodeId } from "../helpers/idEncoder"; // Import the encodeId function
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons

const ITEMS_PER_PAGE = 6; // Number of items per page

const PaginatedCourses = () => {
  const [courses, setCourses] = useState([]); // Initialize as an empty array
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // For pagination

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.getActiveCourses.url, {
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      
      const responseData = await response.json();
  
      setCourses(responseData.data || []);
    } catch (error) {
      alert("Failed to fetch courses: " + error.message);
      setCourses([]);
    } finally {
      setLoading(false);
    }
  };
  const fetchUniversities = async () => {
    try {
      const response = await fetch(SummaryApi.getActiveUniversities.url, { 
        method: "GET",
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();

      setUniversities(responseData.data || []);
      // console.log("Fetched universities data:", responseData.data);
    } catch (error) {
      console.error("Failed to fetch universities: " + error.message);
      setUniversities([]);
    }
  };
  useEffect(() => {
    fetchCourses();
    fetchUniversities(); // Fetch universities as well
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(courses.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const selectedCourses = courses.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return; // Prevent out-of-bounds
    setCurrentPage(page);
    scrollTop(); // Scroll to top when the page changes
  };

  if (loading) {
    return <div className="text-center">Loading...</div>; // You can enhance this with a spinner
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>; // Display error message
  }

  return (
    <div className="courses-page py-12 bg-white">
      <div className="max-w-6xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-center mb-8">
          Our Latest Courses
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {selectedCourses.map((course) => {
               const university = universities.find(u => u.university_id === course.university_id);
               const universityName = university ? university.university_name : "Unknown University"; // Fallback if not found
             
            
                return (
                  <Link
                    key={course.course_id}
                    to={`/courses/${encodeURIComponent(encodeId(course.course_id))}`}
                    className="block"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <CourseCard course={course}  universityName={universityName}
                    universityLocation={university ? university.location : ""} 
                     />
                  </Link>
                );
              })}
            </div> 

        {/* Pagination Controls */}
        <div className="flex justify-center space-x-2 items-center mt-8">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`flex items-center px-2 py-3 rounded-md transition duration-300 ease-in-out ${
              currentPage === 1
                ? "opacity-50 cursor-not-allowed"
                : "bg-green-600 text-white hover:bg-green-700"
            }`}
          >
            <FaChevronLeft className="mr-2" />
          </button>

          <div className="flex space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`mx-1 px-4 py-2 rounded-md transition duration-300 ease-in-out ${
                  currentPage === index + 1
                    ? "bg-green-600 text-white"
                    : "bg-white text-green-600 border border-green-600"
                } hover:bg-green-600 hover:text-white`}
              >
                {index + 1}
              </button>
            ))}
          </div>

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`flex items-center px-2 py-3 rounded-md transition duration-300 ease-in-out ${
              currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : "bg-green-600 text-white hover:bg-green-700"
            }`}
          >
            <FaChevronRight className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginatedCourses;
