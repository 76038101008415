import React from 'react';

const CountryCard = ({ country, onEdit }) => {
    return (
        <div className="border border-gray-300 p-4 rounded-lg w-full h-full overflow-hidden shadow-md flex flex-col">
            <div className="mb-3">
                <h4 className="text-xl font-semibold text-left">{country.country_name}</h4>
                <p className="text-gray-600 text-left">Code: {country.country_code}</p>
            </div>
            <div className="flex justify-end">
                <button 
                    onClick={onEdit} 
                    className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
                >
                    Edit
                </button>
            </div>
        </div>
    );
};

export default CountryCard;
