import React, { useEffect, useState } from 'react';
import SummaryApi from "../common";

const Mode = ({ courseId, mode1, mode2 }) => {
  const [mode1Modules, setMode1Modules] = useState([]);
  const [mode2Modules, setMode2Modules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showMode1, setShowMode1] = useState(false);
  const [showMode2, setShowMode2] = useState(false);

  useEffect(() => {
    const fetchModules = async () => {
      setLoading(true);
      setError('');

      try {
        const mode1Response = await fetch(SummaryApi.getmode1.url, {
          method: SummaryApi.getmode1.method,
          headers: {
            Authorization: "Bearer education_consultancy_916",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ course_id: courseId, mode1 }),
        });

        const mode1Data = await mode1Response.json();
        if (!mode1Data.status) {
          setError('No modules found for Mode 1.');
        } else {
          setMode1Modules(mode1Data.data || []);
        }

        const trimmedMode2 = mode2;
        const mode2Response = await fetch(SummaryApi.getmode2.url, {
          method: SummaryApi.getmode2.method,
          headers: {
            Authorization: "Bearer education_consultancy_916",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ course_id: courseId, mode2: trimmedMode2 }),
        });

        const mode2Data = await mode2Response.json();
        if (!mode2Data.status) {
          setError('No modules found for Mode 2: ' + mode2Data.message);
        } else {
          setMode2Modules(mode2Data.data || []);
        }
      } catch (err) {
        setError('Error retrieving data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchModules();
  }, [courseId, mode1, mode2]);

  const calculateTotalCredits = (modules) => {
    return modules.reduce((total, module) => total + (parseInt(module.credits) || 0), 0);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const totalMode1Credits = calculateTotalCredits(mode1Modules);
  const totalMode2Credits = calculateTotalCredits(mode2Modules);

  return (
    <div>
      {/* Mode 1 */}
      <h5 className="text-lg font-semibold cursor-pointer" onClick={() => setShowMode1(prev => !prev)}>
        {showMode1 ? '▼' : '►'} Mode 1: {mode1}
      </h5>
      {showMode1 && (
        mode1Modules.length > 0 ? (
          <table className="min-w-full table-auto border-collapse mt-4 mb-4">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2 text-left">Name of the Module</th>
                <th className="border px-4 py-2 text-left">Credits</th>
              </tr>
            </thead>
            <tbody>
              {mode1Modules.map((module, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{module.module_name}</td>
                  <td className="border px-4 py-2">{module.credits}</td>
                </tr>
              ))}
              {/* Total Credits for Mode 1 */}
              <tr className="bg-gray-200 font-bold">
                <td className="border px-4 py-2">Total Credits</td>
                <td className="border px-4 py-2">{totalMode1Credits}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>No modules available for Mode 1.</p>
        )
      )}

      {/* Mode 2 */}
      <h5 className="text-lg font-semibold cursor-pointer" onClick={() => setShowMode2(prev => !prev)}>
        {showMode2 ? '▼' : '►'} Mode 2: {mode2}
      </h5>
      {showMode2 && (
        mode2Modules.length > 0 ? (
          <table className="min-w-full table-auto border-collapse  mt-4 mb-4">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2 text-left">Name of the Module</th>
                <th className="border px-4 py-2 text-left">Credits</th>
              </tr>
            </thead>
            <tbody>
              {mode2Modules.map((module, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{module.module_name}</td>
                  <td className="border px-4 py-2">{module.credits}</td>
                </tr>
              ))}
              {/* Total Credits for Mode 2 */}
              <tr className="bg-gray-200 font-bold">
                <td className="border px-4 py-2">Total Credits</td>
                <td className="border px-4 py-2">{totalMode2Credits}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>No modules available for Mode 2.</p>
        )
      )}
    </div>
  );
};

export default Mode;
