import React, { useState, useEffect } from 'react';
import CountryCard from '../components/CountryCard'; // Assuming this component exists
import CountryModal from '../components/CountryModal'; // Assuming this modal exists
import SummaryApi from '../common'; // Import your API definitions
import { toast } from 'react-toastify'; // For notifications

const AllCountries = () => {
    const [countries, setCountries] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingCountry, setEditingCountry] = useState(null);
    const isEditMode = editingCountry !== null; // Determine if in edit mode

    // Fetch countries from the API
    const fetchCountries = async () => {
        try {
            const response = await fetch(SummaryApi.getAllCountries.url, {
                method: SummaryApi.getAllCountries.method,
                headers: {
                    'Authorization': 'Bearer education_consultancy_916', // Replace with your actual token
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setCountries(result.data || []); // Adjust according to the actual response structure
        } catch (error) {
            toast.error('Failed to fetch countries');
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    const showUploadModal = () => {
        setEditingCountry(null);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const openEditModal = (country) => {
        setEditingCountry(country);
        setIsModalVisible(true);
    };

    return (
        <div className="p-5">
            <div className="flex max-w-7xl mx-auto justify-between items-center mb-5">
                <h2 className="text-2xl font-bold">All Countries</h2>
                <button
                    onClick={showUploadModal}
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Upload Country
                </button>
            </div>
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {countries.map((country, index) => (
                        <div className="flex justify-center" key={index}>
                            <CountryCard country={country} onEdit={() => openEditModal(country)} />
                        </div>
                    ))}
                </div>
            </div>
            {isModalVisible && (
                <CountryModal
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    editingCountry={editingCountry}
                    isEditMode={isEditMode}
                    fetchCountries={fetchCountries}
                />
            )}
        </div>
    );
};

export default AllCountries;
