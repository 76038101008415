// idEncoder.js

// Function to encode a course ID
export const encodeId = (id) => {
    // console.log("Encoded ID:", id)
    try {
        return btoa(String(id)); // Convert to string and then encode in base64
        
    } catch (error) {
        console.error("Encoding error:", error);
        return null; // Handle error as needed
    }
};

// Function to decode a course ID
export const decodeId = (encodedId) => {
    // console.log("Decoded ID:", encodedId)
    try {
        // Validate if the encoded ID is properly formatted
        if (!encodedId) {
            throw new Error("Encoded ID is empty or undefined.");
        }

        // Normalize encoded ID by ensuring it has valid base64 length
        const padding = '='.repeat((4 - (encodedId.length % 4)) % 4);
        const base64String = `${encodedId}${padding}`;

        return atob(base64String); // Decode the base64 string
    } catch (error) {
        console.error("Decoding error:", error);
        return null; // Return null or handle the error as needed
    }
};
