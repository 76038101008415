import React from 'react'
import Header from '../components/Header'
import Banner2 from '../components/Banner2'
import CounterArea from '../components/CounterArea'
import IntroArea from '../components/IntroArea'
import AboutArea from '../components/AboutArea'
import PromotionalBanner from '../components/PromotionalBanner'
import FeaturedCourses from '../components/FeaturedCourses'
import Registration from '../components/Registration'
import FeaturedTeachers from '../components/Teacher'
import Testimonial from '../components/Testimonial'
import Events from '../components/Events'
import BlogArea from '../components/BlogArea'
import ClientArea from '../components/ClientArea'
import FooterCallToAction from '../components/FooterCallToAction'
import ImageTesting from '../components/ImageTesting'
import AllUniversities from '../pages/AllUniversities'
import UniversityUser from '../components/UniversityUser'
import PopupForm from '../components/PopupForm'


const Home = () => {
  
  return (
    <div>
      <div className=" flex items-center justify-center bg-gray-100">
            <PopupForm/>
        </div>
      <Banner2 />
      <CounterArea />
      {/* <IntroArea /> */}
      <AboutArea />
      <PromotionalBanner />
     {/* <ImageTesting/> */}
     <UniversityUser />

      <FeaturedCourses title="Explore Featured Courses" />
      {/* <Registration /> */}
      {/* <FeaturedTeachers /> */}
      {/* <Events /> */}
      <Testimonial />
      {/* <BlogArea/> */}
      <ClientArea/>
      {/* <FooterCallToAction/> */}
    </div>
  )
}

export default Home