import React, { useState, useEffect } from "react";
import { FaStar, FaBook, FaFileAlt, FaCheck } from "react-icons/fa";
import SummaryApi from "../common";
import { useParams, Link } from "react-router-dom";
import RelatedCourses from "./RelatedCourses";
import Mode from "./Mode";
import Team1 from "../assets/team/1.png";
import { TiTickOutline } from "react-icons/ti";
import { decodeId } from "../helpers/idEncoder";
import courseSingle from "../assets/course/courseSingle.png";
import StudentEnrollmentModal from './StudentEnrollmentModal'

const CourseInfo = () => {
  const { id } = useParams(); // Get the encoded course ID from the URL
  const decodedId = decodeId(id); // Decode the ID
  const [courseDetails, setCourseDetails] = useState(null);
  const [universities, setUniversities] = useState([]); // State for universities
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");
  const [desiredCategoryId, setDesiredCategoryId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const courseResponse = await fetch(`${SummaryApi.getActiveCourses.url}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer education_consultancy_916",
            "Content-Type": "application/json",
          },
        });

        const universityResponse = await fetch(SummaryApi.getActiveUniversities.url, {
          method: "GET",
          headers: {
            Authorization: "Bearer education_consultancy_916",
            "Content-Type": "application/json",
          },
        });

        const [coursesData, universitiesData] = await Promise.all([
          courseResponse.json(),
          universityResponse.json(),
        ]);

        if (courseResponse.ok && universityResponse.ok) {
          const foundCourse = coursesData.data.find(
            (course) => course.course_id === decodedId
          );

          if (foundCourse) {
            setCourseDetails(foundCourse);
            setDesiredCategoryId(foundCourse.category_id);
            setUniversities(universitiesData.data || []); // Set universities data
          } else {
            throw new Error("Course not found.");
          }
        } else {
          throw new Error(coursesData.message || "Failed to fetch course details.");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [decodedId]);

  if (loading) {
    return <div>Loading course details...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!courseDetails) {
    return <div>No course details available.</div>;
  }
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const {
    course_image2,
    mode1,
    mode2,
    course_name,
    category_name,
    introduction,
    description,
    tags,
    duration,
    credits,
    offered_cost,
    original_cost,
    rating,
    university_id // Make sure this field is available in courseDetails
  } = courseDetails;

  // Find the university name using university_id
  const university = universities.find(u => u.university_id === university_id);
  const universityName = university ? university.university_name : "Unknown University";

  return (
    <div className="bg-white">
      <div className="flex flex-col lg:flex-row py-12 justify-center max-w-7xl mx-auto">
        <div className="lg:w-[95%] w-full p-4">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
            {/* <div className="flex items-center mb-4 md:mb-0">
              <img
                src={Team1}
                alt="Teacher"
                className="w-16 h-16 rounded-full mr-4"
              />
              <div className="text-left">
                <span className="text-lg">Teacher</span>
                <h6 className="text-lg font-semibold">Adward Marin</h6>
              </div>
            </div> */}

            <div className="text-left mb-4 lg:mb-0">
              <span className="text-lg">Category</span>
              <h6 className="text-lg font-semibold">{category_name}</h6>
            </div>
            <div className="text-left mb-4 lg:mb-0">
              <span className="text-lg">University</span>
              <h6 className="text-lg font-semibold">{universityName}</h6> {/* Display university name */}
            </div>
            <div className="text-left mb-4 lg:mb-0">
              <span className="text-lg">Review:</span>
              <div className="flex mt-2">
                {[...Array(5)].map((_, index) => (
                  <FaStar
                    key={index}
                    className={`text-lg font-semibold ${index < rating ? "text-yellow-500" : "text-gray-300"
                      }`}
                  />
                ))}
              </div>
            </div>

            <div className="text-left mt-3 md:mt-0">
              <button
                onClick={openModal}
                className="btn text-sm btn-base bg-green-600 text-white rounded-lg hover:bg-green-700 p-4"
              >
                Get Enroll
              </button>
            </div>

            {/* Modal for enrollment */}
            {isModalOpen && (
              <StudentEnrollmentModal
                isOpen={isModalOpen}
                onClose={closeModal}
                courseDetails={courseDetails}
                universityName={universityName}
                courseName={course_name}
                categoryName={category_name}
              />
            )}


          </div>

          <div className="flex items-center mb-4">
            <img
              src={course_image2 ? `${SummaryApi.courseImg2.url}/${course_image2}` : courseSingle}
              alt="Course"
              className="w-full h-96 object-cover rounded-lg"
            />
          </div>

          <div className="text-center">
            <ul className="flex justify-start space-x-4 border-b border-gray-300">
              <li className="nav-item">
                <button
                  className={`nav-link py-2 px-4 ${activeTab === "tab1"
                    ? "border-t-2 border-green-500 font-semibold"
                    : "text-gray-500"
                    }`}
                  onClick={() => setActiveTab("tab1")}
                >
                  <FaBook className="inline-block mr-1" /> Overview
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link py-2 px-4 ${activeTab === "tab2"
                    ? "border-t-2 border-green-500 font-semibold"
                    : "text-gray-500"
                    }`}
                  onClick={() => setActiveTab("tab2")}
                >
                  <FaFileAlt className="inline-block mr-1" /> Course Modes
                </button>
              </li>
            </ul>
          </div>

          <div className="mt-4 text-left">
            {activeTab === "tab1" && (
              <div>
                <div className="shadow-lg border-t-4 border-navy-600 p-6 rounded-lg">
                  <h1 className="text-2xl py-4 font-bold text-gray-800">
                    Program Highlights
                  </h1>
                  <ul className="gap-4 text-lg text-gray-600">
                    <li className="flex items-center mb-2">
                      <TiTickOutline />
                      <strong>Duration:</strong> {duration}
                    </li>
                    <li className="flex items-center mb-2">
                      <TiTickOutline />
                      <strong>Credits:</strong> {credits}
                    </li>
                    <li className="flex items-start mb-2">
                      <TiTickOutline />
                      <strong>Certifications:</strong>
                      <span className="ml-2">
                        1) SQA Certification
                        <br />
                        2) CMA Certification
                        <br />
                        3) Degree Certificate
                      </span>
                    </li>
                    <li className="flex items-center mb-2">
                      <TiTickOutline />
                      <strong>Price:</strong>
                      <p className="text-lg font-bold text-green-500 pl-2">
                        ${offered_cost}
                      </p>
                      <p className="text-sm line-through text-gray-500 pl-2">
                        ${original_cost}
                      </p>
                    </li>
                  </ul>
                </div>

                <h2 className="text-2xl py-4 font-bold text-gray-800">
                  About MBA with {course_name}
                </h2>

                <p className="text-lg text-gray-500 text-justify">
                  {introduction}
                </p>
                <p className="text-lg text-gray-500 my-4 text-justify">
                  {description}
                </p>

                <ul className="list-none text-lg text-gray-500">
                  {tags &&
                    tags.map((tag, index) => (
                      <li key={index} className="flex items-center mt-2">
                        <FaCheck className="text-green-500 text-sm mr-2" />
                        {tag}
                      </li>
                    ))}
                </ul>
              </div>
            )}
            {activeTab === "tab2" && (
              <div>
                <h4 className="text-2xl py-4 font-bold text-gray-800">Course Modes</h4>
                <Mode
                  courseId={decodedId}
                  mode1={courseDetails.mode1}
                  mode2={courseDetails.mode2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto text-left pb-8">
        <RelatedCourses
          categoryId={desiredCategoryId}
          currentCourseId={decodedId}
        />
      </div>
    </div>
  );
};

export default CourseInfo;
