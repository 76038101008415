import './App.css';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin-panel');
  const isAdminLoginRoute = location.pathname === '/admin-login';

  return (
    <div className="App bg-gradient-to-r from-white to-[#ceaad1]">
      <ToastContainer position='top-center' />
      <main className='min-h-screen pt-0'>
        {!isAdminRoute && !isAdminLoginRoute && <Header />}
        <Outlet />
      </main>
      {!isAdminRoute && !isAdminLoginRoute && <Footer />}
      <ScrollToTopButton />
    </div>
  );
}

export default App;
