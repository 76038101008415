import React from 'react';
import AboutImg1 from '../assets/about/4.png';
import AboutImg2 from '../assets/about/6.png';
import AboutImg3 from '../assets/about/5.png';
import AboutImg4 from '../assets/about/7.png';

const AboutArea = () => {
    return (
        <div className="max-w-7xl mx-auto p-10">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    {/* Image Section */}
                    <div className="lg:w-1/2 w-full px-10">
                        <div className="flex flex-wrap">
                            <div className="sm:w-1/2 w-full mt-8 p-4">
                                <div className="transition-transform duration-300 transform hover:scale-105">
                                    <img src={AboutImg1} alt="About 1" className="w-full rounded-lg" />
                                </div>
                                <div className="transition-transform duration-300 transform hover:scale-105 mt-4">
                                    <img src={AboutImg2} alt="About 2" className="w-full rounded-lg" />
                                </div>
                            </div>
                            <div className="sm:w-1/2 w-full">
                                <div className="transition-transform duration-300 transform hover:scale-105">
                                    <img src={AboutImg3} alt="About 3" className="w-full rounded-lg" />
                                </div>
                                <div className="transition-transform duration-300 transform hover:scale-105 mt-4">
                                    <img src={AboutImg4} alt="About 4" className="w-full rounded-lg" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Text Section */}
                    <div className="lg:w-1/2 w-full flex items-center mt-4 lg:mt-0 text-left">
                        <div className='bg-slate-100 p-6 md:p-14 rounded-lg'>
                            <h5 className="text-gray-400 text-lg md:text-xl font-bold">Who we are</h5>
                            <h2 className="text-3xl md:text-5xl font-bold mt-2">The leading platform for learning courses</h2>
                            <p className="mt-4 md:text-lg text-gray-600">You can start and finish one of these popular courses in under a day - for free! Check out the list below:</p>
                            <ul className="mt-4 list-disc md:text-lg list-inside">
                                <li className="flex md:items-center text-gray-700">
                                    <svg
                                        className="w-5 h-5 mr-2 text-green-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                    Access to 4,000+ of our top courses
                                </li>
                                <li className="flex md:items-center text-gray-700">
                                    <svg
                                        className="w-5 h-5 mr-2 text-green-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                    Explore a variety of fresh topics
                                </li>
                                <li className="flex md:items-center text-gray-700">
                                    <svg
                                        className="w-5 h-5 mr-2 text-green-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                    Find the right instructor for you
                                </li>
                            </ul>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AboutArea;
