import React from 'react';
import BgImage from '../assets/bg/3.png';
import { Link } from 'react-router-dom';

const PageTitle = ({ title, pageName }) => {
    return (
        <div
            className="relative bg-cover bg-center py-48"
            style={{ backgroundImage: `url(${BgImage})` }}
        >
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <div className='max-w-7xl mx-auto px-4'>
                <div className="container">
                    <div className="flex flex-col items-start md:flex-row">
                        <div className="w-full md:w-7/12">
                            <div className="relative z-10">
                                <h2 className="text-white text-5xl text-left font-bold mb-2">{title}</h2>
                                <div className="flex space-x-2 text-white mt-8">
                                    <div className='flex space-x-1 px-4 py-2 rounded-3xl text-lg bg-white bg-opacity-10'>
                                        <span>
                                            <Link to="/" className="hover:underline text-[#08bca4]">Home /</Link>
                                            <span className="text-gray-300"> {pageName}</span>
                                        </span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageTitle;
