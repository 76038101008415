import React from 'react';
import aboutImage1 from '../assets/bg/6.png';
import aboutImage2 from '../assets/bg/5.png';
import AboutProfile from './AboutProfile';
import { Link } from 'react-router-dom';

const AboutSection = () => {
    return (
        <div>
        <div className="bg-gray-200 pt-24 pb-24">
            <div className='max-w-7xl mx-auto'>
                <div className="flex flex-col justify-center items-center lg:flex-row">
                    <div className="md:hidden lg:w-1/2 flex justify-center items-center">
                        <img src={aboutImage1} alt="Educational Background" className="w-full h-auto rounded-lg" />
                    </div>
                    <div className="lg:w-1/2 bg-gray-200 p-8">
                        <h2 className="text-4xl font-bold mb-4 text-left">Every course on <span className='bg-gradient-to-r from-[#759683] to-[#a22569] bg-clip-text text-transparent'>Uni Harbour</span> is taught by top instructors</h2>
                        <p className="text-gray-700 text-lg mb-6 text-left">
                            At Uni Harbour, we believe in empowering learners with the skills they need to succeed in a fast-paced world. 
                            Our courses cover a wide range of topics, ensuring you can find the right fit for your career goals.
                        </p>
                        <div className="flex justify-start">
                            <Link to="/courses"
                            onClick={() => window.scrollTo(0, 0)}
                            className="bg-red-500 text-white py-3 px-6 rounded hover:bg-red-600 transition">
                                Find A Course
                            </Link>
                        </div>
                    </div>
                    <div className="hidden lg:w-1/2 md:flex justify-center items-center">
                        <img src={aboutImage1} alt="Educational Background" className="w-full h-auto rounded-lg" />
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center lg:flex-row">
                    <div className="lg:w-1/2 flex justify-center items-center">
                        <img src={aboutImage2} alt="Educational Background" className="w-full h-auto rounded-lg" />
                    </div>
                    <div className="lg:w-1/2 bg-gray-200 p-8">
                        <h2 className="text-4xl font-bold mb-4 text-left">Unlock Your Potential with <span className='bg-gradient-to-r from-[#759683] to-[#a22569] bg-clip-text text-transparent'>Uni Harbour</span></h2>
                        <p className="text-gray-700 text-lg mb-6 text-left">
                            Our expert instructors bring real-world experience to the classroom. 
                            Join a vibrant community of learners and gain the knowledge needed to thrive in your chosen field.
                        </p>
                        <div className="flex justify-start">
                            <Link to="/courses"
                            onClick={() => window.scrollTo(0, 0)}
                             className="bg-red-500 text-white py-3 px-6 rounded hover:bg-red-600 transition">
                                Explore Courses
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
      <AboutProfile/>
        </div>
    );
};

export default AboutSection;
