import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const UniversityModal = ({ visible, onCancel, isEditMode, fetchUniversities, editingUniversity }) => {
    const [imageUrl1, setImageUrl1] = useState('');
    const [imageUrl2, setImageUrl2] = useState('');
    const [countries, setCountries] = useState([]);
    const [formValues, setFormValues] = useState({
        university_name: '',
        country_id: '',
        location: '',
        description: '',
        is_active: false,
    });

    useEffect(() => {
        const fetchCountries = async () => {
            const response = await fetch(SummaryApi.getAllCountries.url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setCountries(result.data || []);
        };

        fetchCountries();
    }, []);

    useEffect(() => {
        if (visible) {
            if (isEditMode && editingUniversity) {
                const { university_name, country_id, location, description, university_image1, university_image2, is_active } = editingUniversity;
                setFormValues({
                    university_name,
                    country_id,
                    location,
                    description,
                    is_active: is_active === "0" ? false : true,
                });
                setImageUrl1(SummaryApi.universityImg.url + university_image1);
                setImageUrl2(SummaryApi.universityImg.url + university_image2);
            } else {
                setFormValues({
                    university_name: '',
                    country_id: '',
                    location: '',
                    description: '',
                    is_active: false,
                });
                setImageUrl1('');
                setImageUrl2('');
            }
        }
    }, [visible, isEditMode, editingUniversity]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleUploadChange = (setImageUrl) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const uploadedImage = URL.createObjectURL(file);
            setImageUrl(uploadedImage);
            setFormValues((prevValues) => ({ ...prevValues, [event.target.name]: file }));
        } else {
            setImageUrl('');
        }
    };

    const handleOk = async () => {
        const apiUrl = isEditMode ? SummaryApi.updateUniversity.url : SummaryApi.createUniversity.url;
        const formData = new FormData();
    
        // Append form values to FormData
        formData.append('university_name', formValues.university_name);
        formData.append('country_id', formValues.country_id);
        formData.append('location', formValues.location);
        formData.append('description', formValues.description);
        formData.append('is_active', formValues.is_active ? 1 : 0);
    
        // Append images if they exist
        if (imageUrl1) {
            const image1File = document.querySelector('input[name="image1"]').files[0];
            if (image1File) {
                formData.append('image1', image1File);
            }
        }
    
        if (imageUrl2) {
            const image2File = document.querySelector('input[name="image2"]').files[0];
            if (image2File) {
                formData.append('image2', image2File);
            }
        }
    
        // If in edit mode, include the university ID
        if (isEditMode) {
            formData.append('university_id', editingUniversity.university_id);
        }
    
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer education_consultancy_916',
            },
            body: formData,
        });

        const responseData = await response.json();
    
        if (responseData.status) {
            fetchUniversities();
            toast.success(responseData.message);
            onCancel();
        } else {
            alert('Failed to save the university.');
        }
    };
    
    if (!visible) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg w-full overflow-auto max-h-[80vh] max-w-4xl mt-5">
                <h2 className="sticky top-0 bg-white text-xl font-bold mb-4 py-4 border-b">{isEditMode ? "Edit University" : "Upload University"}</h2>
                <form className='text-left' onSubmit={e => { e.preventDefault(); handleOk(); }}>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">University Name</label>
                        <input name="university_name" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.university_name} onChange={handleChange} />
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Country</label>
                        <select name="country_id" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.country_id} onChange={handleChange}>
                            <option value="" disabled>Select a country</option>
                            {countries.map(country => (
                                <option key={country.country_id} value={country.country_id}>{country.country_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Location</label>
                        <input name="location" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.location} onChange={handleChange} />
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Description</label>
                        <textarea name="description" rows="4" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.description} onChange={handleChange}></textarea>
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">University Image 1</label>
                        <input type="file" name="image1" accept="image/*" onChange={handleUploadChange(setImageUrl1)} className="mt-1 block w-full border border-gray-300 rounded-md p-2" />
                        {imageUrl1 && <img src={imageUrl1} alt="Preview 1" className="mt-2 w-24" />}
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">University Image 2</label>
                        <input type="file" name="image2" accept="image/*" onChange={handleUploadChange(setImageUrl2)} className="mt-1 block w-full border border-gray-300 rounded-md p-2" />
                        {imageUrl2 && <img src={imageUrl2} alt="Preview 2" className="mt-2 w-24" />}
                    </div>
                    <div className="mb-4 px-6">
                        <label className="flex items-center">
                            <input 
                                type="checkbox" 
                                name="is_active" 
                                checked={formValues.is_active} 
                                onChange={handleChange} 
                                className="mr-2"
                            />
                            Is Active
                        </label>
                    </div>
                    <div className="bg-white bottom-0 sticky px-6 py-4 flex justify-between">
                        <button type="button" onClick={onCancel} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400">Cancel</button>
                        <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">{isEditMode ? "Update" : "Submit"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UniversityModal;
