import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import AdminPanel from '../pages/AdminPanel';
import AllUniversities from '../pages/AllUniversities';
import AllCountries from '../pages/AllCountries';
import AllCourses from '../pages/AllCourses';
import Home from '../pages/Home';
import About from '../pages/About';
import Course from '../pages/Course';
import CourseDetail from '../pages/CourseDetail';
import ContactUs from '../components/ContactUs';
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import AllStudents from '../pages/AllStudents';
import UniversityCourses from '../components/UniversityCourses';
import Profile from '../pages/Profile'
import AdminLogin from '../pages/AdminLogin';
import AllCategories from '../pages/AllCategories'
import EnrolledStudents from '../pages/EnrolledStudents'
import StudentEnrollments from '../pages/StudentEnrollments'


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { path: "", element: <Home /> },
           
            { path: "/admin-panel", element: <AdminPanel />,
                
                children: [
                    { path: "", element: <Dashboard /> },
                    { path: "dashboard", element: <Dashboard /> },
                    { path: "all-universities", element: <AllUniversities /> },
                    { path: "all-countries", element: <AllCountries /> },
                    { path: "all-courses", element: <AllCourses /> },
                    { path: "all-students", element: <AllStudents /> },
                    { path: "all-categories", element: <AllCategories /> },
                    { path: "enrolled-students", element: <EnrolledStudents /> },
                    { path: "student/:id", element: <StudentEnrollments /> },


                    
                ]
            },
            { path: "/admin-login", element: <AdminLogin /> },
            { path: "/about-us", element: <About /> },
            // { path:"/university-courses/:universityId", element:<UniversityCourses /> },
            { path: "/university-courses/:universityId", element: <UniversityCourses /> },

            { path: "/courses", element: <Course /> },
            { path:"/courses/:id", element:<CourseDetail/>},
            { path: "/contact-us", element: <ContactUs /> },
            { path: "/login", element: <Login /> },
            { path: "/signup", element: <SignUp /> },
            { path: "/profile", element: <Profile /> },
      
        ]
    }
]);


export default router;
