import React from 'react'
import PageTitle from '../components/PageTitle'
import AboutArea from '../components/AboutArea'
import IconRow from '../components/IconRow'
import AboutSection from '../components/AboutSection'
import Testimonial from '../components/Testimonial'
import FooterCallToAction from '../components/FooterCallToAction'
import ClientArea from '../components/ClientArea'

const About = () => {
  return (
    <div>
        <PageTitle title="The world’s best learning experience." pageName="About Us" />
        <AboutArea/>
        <IconRow/>
        <AboutSection/>
        <Testimonial/>
        <ClientArea/>
        {/* <FooterCallToAction/> */}
    </div>
  )
}

export default About