import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify'; // For notifications
import SummaryApi from '../common'; // Import your API definitions

const CategoryModal = ({ visible, onCancel, isEditMode, editingCategory, fetchCategories }) => {
    const [formValues, setFormValues] = useState({
        category_name: '',
        university_id: '', // Changed to store university_id
        is_active: false,
    });

    const [universities, setUniversities] = useState([]);

    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const response = await fetch(SummaryApi.getActiveUniversities.url, {
                    method: SummaryApi.getActiveUniversities.method,
                    headers: {
                        'Authorization': 'Bearer education_consultancy_916',
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();
                setUniversities(result.data || []); // Adjust according to the actual response structure
            } catch (error) {
                toast.error('Failed to fetch universities');
                console.error(error);
            }
        };

        fetchUniversities();
    }, []);

    useEffect(() => {
        if (visible && isEditMode && editingCategory) {
            setFormValues({
                category_name: editingCategory.category_name,
                university_id: editingCategory.university_id, // Changed to university_id
                is_active: editingCategory.is_active === "0" ? false : true,
            });
        } else {
            setFormValues({
                category_name: '',
                university_id: '',
                is_active: false,
            });
        }
    }, [visible, isEditMode, editingCategory]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleOk = async () => {
        const apiUrl = isEditMode ? SummaryApi.updateCategory.url : SummaryApi.createCategory.url;
        const formData = new FormData();

        // Append form values to FormData
        formData.append('category_name', formValues.category_name);
        formData.append('university_id', formValues.university_id); // Append university_id
        // formData.append('is_active', formValues.is_active ? 1 : 0);
        formData.append('is_active',1)

        // If in edit mode, include the category ID
        if (isEditMode) {
            formData.append('category_id', editingCategory.category_id);
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                },
                body: formData,
            });

            const responseData = await response.json();

            if (responseData.status) {
                fetchCategories(); // Refresh the categories list
                toast.success(responseData.message);
                onCancel(); // Close modal
            } else {
                toast.error('Failed to save the category.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while saving the category.');
        }
    };

    if (!visible) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                <h2 className="text-xl font-bold mb-4">{isEditMode ? "Edit Category" : "Upload Category"}</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleOk(); }} className='text-left'>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">Category Name</label>
                        <input 
                            name="category_name" 
                            required 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2" 
                            value={formValues.category_name} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">University</label>
                        <select 
                            name="university_id" // Changed to university_id
                            required 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2" 
                            value={formValues.university_id} 
                            onChange={handleChange}
                        >
                            <option value="" disabled>Select a university</option>
                            {universities.map(university => (
                                <option key={university.university_id} value={university.university_id}>
                                    {university.university_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="mb-4">
                        <label className="flex items-center">
                            <input 
                                type="checkbox" 
                                name="is_active" 
                                checked={formValues.is_active} 
                                onChange={handleChange} 
                                className="mr-2"
                            />
                            Is Active
                        </label>
                    </div> */}
                    <div className="flex justify-between mt-4">
                        <button 
                            type="button" 
                            onClick={onCancel} 
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button 
                            type="submit" 
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            {isEditMode ? "Update" : "Submit"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CategoryModal;
