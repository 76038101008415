import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify'; // For notifications
import SummaryApi from '../common'; // Import your API definitions

const CountryModal = ({ visible, onCancel, isEditMode, editingCountry, fetchCountries }) => {
    const [formValues, setFormValues] = useState({
        country_name: '',
        country_code: '',
        is_active: false,
    });

    useEffect(() => {
        if (visible && isEditMode && editingCountry) {
            setFormValues({
                country_name: editingCountry.country_name,
                country_code: editingCountry.country_code,
                is_active: editingCountry.is_active === "0" ? false : true,
            });
        } else {
            setFormValues({
                country_name: '',
                country_code: '',
                is_active: false, 
            });
        }
    }, [visible, isEditMode, editingCountry]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleOk = async () => {
        const apiUrl = isEditMode ? SummaryApi.updateCountry.url : SummaryApi.createCountry.url;
        const formData = new FormData();
        console.log(formValues, editingCountry.country_id)
        
        // Append form values to FormData
        formData.append('country_name', formValues.country_name);
        formData.append('country_code', formValues.country_code);
        formData.append('is_active', formValues.is_active ? 1 : 0);

        // If in edit mode, include the country ID
        if (isEditMode) {
            formData.append('country_id', editingCountry.country_id);
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                },
                body: formData,
            });

            const responseData = await response.json();

            if (responseData.status) {
                fetchCountries(); // Refresh the countries list
                toast.success(responseData.message);
                onCancel(); // Close modal
            } else {
                toast.error('Failed to save the country.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while saving the country.');
        }
    };

    if (!visible) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                <h2 className="text-xl font-bold mb-4">{isEditMode ? "Edit Country" : "Upload Country"}</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleOk(); }} className='text-left'>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">Country Name</label>
                        <input 
                            name="country_name" 
                            required 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2" 
                            value={formValues.country_name} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">Country Code</label>
                        <input 
                            name="country_code" 
                            required 
                            placeholder="e.g., US" 
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2" 
                            value={formValues.country_code} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input 
                                type="checkbox" 
                                name="is_active" 
                                checked={formValues.is_active} 
                                onChange={handleChange} 
                                className="mr-2"
                            />
                            Is Active
                        </label>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button 
                            type="button" 
                            onClick={onCancel} 
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                        <button 
                            type="submit" 
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                        >
                            {isEditMode ? "Update" : "Submit"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CountryModal;
