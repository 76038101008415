import React from 'react';
import SummaryApi from '../common';

const UniversityCard = ({ university, onEdit }) => {
    return (
        <div className='border border-gray-300 rounded-lg w-full h-full overflow-hidden shadow-md flex flex-col'>
            <img 
                alt={university.university_name} 
                src={SummaryApi.universityImg.url + university.university_image1} 
                className='h-40 w-full object-cover'
            />
            <div className='flex flex-col justify-between text-left p-4 flex-grow'>
                <h3 className='mb-2'>{university.university_name}</h3>
                <p><strong>Location:</strong> {university.location}</p>
                <p className='flex-grow'><strong>Description:</strong> {university.description}</p>
                <div className='flex justify-end mt-4'>
                    <button 
                        onClick={onEdit} 
                        className='bg-blue-500 text-white rounded px-4 py-2'
                    >
                        Edit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UniversityCard;
