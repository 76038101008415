import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import SummaryApi from '../common/index';
import CourseCard from './CourseCard'; // Import the CourseCard component
import { encodeId } from "../helpers/idEncoder";
import t3 from '../assets/nocoursefound.png';

const UniversityCourses = () => {
    const { universityId } = useParams(); // Get university ID from URL
    const [courses, setCourses] = useState([]);
    const [universityName, setUniversityName] = useState(''); // State for university name
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch courses for the given university
    const fetchCourses = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(SummaryApi.getUniversitiesCourses.url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ university_id: universityId }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok. Status: ' + response.status);
            }

            const responseData = await response.json();
            if (responseData.status && responseData.data) {
                setCourses(responseData.data);
                // console.log('Courses for university ID:', universityId, responseData.data);
            } else {
                setCourses([]); // No courses found
            }
        } catch (error) {
            console.error('Failed to fetch courses:', error);
            setCourses([]); // Set courses to empty
        } finally {
            setLoading(false);
        }
    };

    // Fetch university data (name) from the getActiveUniversities API
    const fetchUniversityName = async () => {
        try {
            const response = await fetch(SummaryApi.getActiveUniversities.url);
            if (!response.ok) {
                throw new Error('Failed to fetch university details.');
            }

            const data = await response.json();
            // console.log("Fetched universities data:", data); 

            // Check if `data` is an array and find the university by `universityId`
            if (Array.isArray(data.data)) {
                const university = data.data.find(u => u.university_id === universityId);
                if (university) {
                    setUniversityName(university.university_name); // Set the university name
                } else {
                    setUniversityName('Unknown University');
                }
            } else {
                setUniversityName('Unknown University');
            }
        } catch (error) {
            console.error('Failed to fetch university details:', error);
            setUniversityName('Unknown University');
        }
    };

    useEffect(() => {
        if (universityId) {
            fetchUniversityName(); // Fetch the university name
            fetchCourses(); // Fetch the courses for this university
        }
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, [universityId]);

    return (
        <div className="p-5 mb-5">
            {loading ? (
                <p>Loading courses...</p>
            ) : (
                <div className='courses-page py-12'>
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-6">Courses at {universityName}</h2> {/* Display the university name here */}

                        {courses.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {courses.map((course) => (
                                    <Link 
                                        key={course.course_id}
                                        to={`/courses/${encodeURIComponent(encodeId(course.course_id))}`} // Navigate to course details
                                        className="block"
                                        onClick={() => window.scrollTo(0, 0)} // Scroll to top on click
                                    >
                                        <CourseCard
                                            course={course}
                                            universityName={universityName} // Pass the university name here
                                        />
                                    </Link>
                                ))}
                            </div>
                        ) : (
                            <div className="flex flex-col items-center">
                                <p className="text-2xl font-bold text-center mb-6">No courses found for this university.</p>
                                <img 
                                    src={t3}
                                    alt="No courses available"
                                    className="w-1/2 h-auto mb-4"
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UniversityCourses;
