import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import SummaryApi from '../common';

const StudentEnrollments = () => {
  const { id } = useParams();
  const [enrollments, setEnrollments] = useState([]);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        const response = await fetch(SummaryApi.getStudentById.url, {
          method: SummaryApi.getStudentById.method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ student_id: atob(id) }),
        });

        if (response.ok) {
          const data = await response.json();
          setEnrollments(data.enrollment);
          setStudent(data.student);
        } else {
          setError('Failed to fetch enrollments');
        }
      } catch (error) {
        setError('Error fetching enrollments: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEnrollments();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const daySuffix = (day) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const value = day % 10;
      return suffixes[(value > 3 || Math.floor(day % 100 / 10) === 1) ? 0 : value];
    };
    return `${date.getDate()}${daySuffix(date.getDate())} ${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'University Image', accessor: 'university_image1',
        Cell: ({ value }) => (
          <img src={SummaryApi.universityImg.url + value} alt="University" className="w-16 h-16 object-cover rounded" />
        )
      },
      { Header: 'University Name', accessor: 'university_name' },
      { Header: 'Course Name', accessor: 'course_name' },
      { Header: 'Enrollment Code', accessor: 'enrollment_code' },
      {
        Header: 'Enrollment Date', accessor: 'enrollment_date',
        Cell: ({ value }) => formatDate(value)
      },
      { Header: 'Full Name', accessor: 'full_name' },
      { Header: 'Mobile', accessor: 'mobile_number' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'City', accessor: 'city' },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: enrollments });

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-semibold text-left mb-4">Student Enrollments</h2>

      {student && (
        <div className="flex-shrink-0 mb-4 lg:w-1/3 p-4 bg-gray-100 rounded-lg shadow-md">
          <div className="flex items-center">
            <div className='w-32'>
              <img src={SummaryApi.studentImg.url + student.student_image} alt="Student" className="w-24 h-24 rounded-full border border-gray-300" />
            </div>
            <div className="ml-4 text-left">
              <h3 className="text-xl font-semibold">{student.full_name}</h3>
              <p className="text-gray-600">{student.email}</p>
              <p className="text-gray-600">{student.mobile_number}</p>
              <p className="text-gray-600">{student.city}</p>
            </div>
          </div>
        </div>
      )}

      {loading && <p className="text-center text-lg text-gray-500">Loading...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      <div className="overflow-x-auto">
        <table {...getTableProps()} className="min-w-full mb-4 rounded-lg shadow-md overflow-hidden">
          <thead className="bg-gray-200">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} key={column.id} className="px-4 py-2 text-left text-gray-700 font-semibold">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id} className="hover:bg-gray-100 transition duration-200">
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} key={cell.column.id} className="border px-4 py-2">
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentEnrollments;
