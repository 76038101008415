// UniversityCardUser.js
import React from 'react';
import { Link } from 'react-router-dom';
import SummaryApi from '../common';

const UniversityCardUser = ({ university }) => {
    return (
        <Link 
            to={`/university-courses/${university.university_id}`} 
            className='border border-gray-300 rounded-lg w-full h-96 overflow-hidden shadow-md flex flex-col cursor-pointer' 
        >
            <img 
                alt={university.university_name} 
                src={SummaryApi.universityImg.url + university.university_image1} 
                className='h-40 w-full object-cover'
            />
            <div className='flex flex-col justify-between text-left p-4 flex-grow'>
                <h3 className='mb-2 text-xl text-left font-bold'>{university.university_name}</h3>
                <p><strong>Location:</strong> {university.location}</p>
                <p className='flex-grow'><strong>Description:</strong> {university.description}</p>
            </div>
        </Link>
    );
};


export default UniversityCardUser;
