import React, { useState, useEffect } from 'react';
import UniversityCard from '../components/UniversityCard';
import UniversityModal from '../components/UniversityModal';
import SummaryApi from '../common/index';

const AllUniversities = () => {
    const [universities, setUniversities] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingUniversity, setEditingUniversity] = useState(null);

    const fetchUniversities = async () => {
        try {
            const response = await fetch(SummaryApi.getAllUniversities.url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                    'Content-Type': 'application/json',
                },
            });
            const responseData = await response.json();
            setUniversities(responseData.data || []);
        } catch (error) {
            alert('Failed to fetch universities');
            setUniversities([]);
        }
    };

    useEffect(() => {
        fetchUniversities();
    }, []);

    const showUploadModal = () => {
        setIsEditMode(false);
        setEditingUniversity(null);
        setIsModalVisible(true);
    };

    const showEditModal = (university) => {
        setIsEditMode(true);
        setEditingUniversity(university);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="p-5">
            <div className="flex max-w-7xl mx-auto justify-between items-center mb-5">
                <h2 className="text-2xl font-bold">All Universities</h2>
                <button 
                    onClick={showUploadModal} 
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Upload University
                </button>
            </div>
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {universities.map((university, index) => (
                        <div className="flex justify-center" key={index}>
                            <UniversityCard university={university} onEdit={() => showEditModal(university)} />
                        </div>
                    ))}
                </div>
            </div>
            {isModalVisible && (
                <UniversityModal
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    isEditMode={isEditMode}
                    editingUniversity={editingUniversity}
                    fetchUniversities={fetchUniversities}
                />
            )}
        </div>
    );
};

export default AllUniversities;
