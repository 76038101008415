import React from "react";
import SummaryApi from "../common";

const CourseCard = ({ course, universityName, universityLocation, onEdit }) => {
    const imageUrl = SummaryApi.courseActiveImg.url + course.course_image1;

    return (
        <div className="border border-gray-300 rounded-lg w-full h-full overflow-hidden shadow-md flex flex-col">
            <img
                alt={course.course_name}
                src={imageUrl}
                className="h-56 w-full object-cover"
            />
            <div className="flex flex-col justify-between text-left p-4 flex-grow">
                <h3 className="mb-2 text-xl text-left font-bold">{course.course_name}</h3>
                {/* <p>
                    <strong>Category:</strong> {course.category_name}
                </p> */}
                <p>
                    <strong>Duration:</strong> {course.duration}
                </p>
                <p className="line-clamp-3">
                    <strong>Description:</strong> {course.description}
                </p>
                {/* <p>{universityName} - {universityLocation}</p> */}
                <div className="flex justify-end mt-4">
                    <button 
                        onClick={() => onEdit(course)} 
                        className='bg-blue-500 text-white rounded px-4 py-2'
                    >
                        Edit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CourseCard;
