import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const CourseModal = ({ visible, onCancel, isEditMode, fetchCourses, editingCourse }) => {
    const [imageUrl1, setImageUrl1] = useState('');
    const [imageUrl2, setImageUrl2] = useState('');
    const [formValues, setFormValues] = useState({
        course_name: '',
        description: '',
        cost: '',
        duration: '',
        is_active: false,
        category_id: '',
        university_id: '',
        introduction: '',
        original_cost: '',
        offered_cost: '',
        credits: '',
        rating: 0,
    });
    const [categories, setCategories] = useState([]); 
    const [universities, setUniversities] = useState([]);

    useEffect(() => {
        if (visible) {
            fetchUniversities(); // Fetch universities when the modal is visible
            if (isEditMode && editingCourse) {
                const {
                    course_name,
                    description,
                    cost,
                    duration,
                    is_active,
                    course_image1,
                    course_image2,
                    category_id,
                    university_id,
                    introduction,
                    original_cost,
                    offered_cost,
                    credits,
                    rating,
                } = editingCourse;

                setFormValues({
                    course_name,
                    description,
                    cost,
                    duration,
                    is_active: is_active === "0" ? false : true,
                    category_id,
                    university_id,
                    introduction,
                    original_cost,
                    offered_cost,
                    credits,
                    rating,
                });
                setImageUrl1(SummaryApi.courseImg.url + course_image1);
                setImageUrl2(SummaryApi.courseImg.url + course_image2);
                fetchCategoriesByUniversity(university_id); // Fetch categories for editing course's university
            } else {
                resetForm();
            }
        }
    }, [visible, isEditMode, editingCourse]);

    const resetForm = () => {
        setFormValues({
            course_name: '',
            description: '',
            cost: '',
            duration: '',
            is_active: false,
            category_id: '',
            university_id: '',
            introduction: '',
            original_cost: '',
            offered_cost: '',
            credits: '',
            rating: 0,
        });
        setImageUrl1('');
        setImageUrl2('');
        setCategories([]);
    };

    const fetchUniversities = async () => {
        try {
            const response = await fetch(`${SummaryApi.getActiveUniversities.url}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                },
            });
            const data = await response.json();
            if (data.status) {
                setUniversities(data.data);
            } else {
                toast.error('Failed to fetch universities.');
            }
        } catch (error) {
            console.error('Error fetching universities:', error);
            toast.error('Error fetching universities.');
        }
    };

    const fetchCategoriesByUniversity = async (universityId) => {
        try {
            const response = await fetch(`${SummaryApi.getCategoryByUniversity.url}`, {
                method: SummaryApi.getCategoryByUniversity.method,
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ university_id: universityId }),
            });
            const data = await response.json();
            setCategories(data.data || []);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error('Error fetching categories.');
        }
    };

    const handleUniversityChange = (e) => {
        const universityId = e.target.value;
        setFormValues((prevValues) => ({ ...prevValues, university_id: universityId, category_id: '' }));
        fetchCategoriesByUniversity(universityId); // Fetch categories based on selected university
    };

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleUploadChange = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const uploadedImage = URL.createObjectURL(file);
            if (index === 1) {
                setImageUrl1(uploadedImage);
                setFormValues((prevValues) => ({ ...prevValues, course_image1: file }));
            } else {
                setImageUrl2(uploadedImage);
                setFormValues((prevValues) => ({ ...prevValues, course_image2: file }));
            }
        } else {
            if (index === 1) {
                setImageUrl1('');
            } else {
                setImageUrl2('');
            }
        }
    };

    const handleRatingChange = (newRating) => {
        setFormValues((prevValues) => ({ ...prevValues, rating: newRating }));
    };

    const handleOk = async () => {
        const apiUrl = isEditMode ? SummaryApi.updateCourse.url : SummaryApi.createCourse.url;
        const formData = new FormData();

        // Append form values to FormData
        formData.append('course_name', formValues.course_name);
        formData.append('description', formValues.description);
        formData.append('cost', formValues.cost);
        formData.append('duration', formValues.duration);
        formData.append('is_active', formValues.is_active ? 1 : 0);
        formData.append('category_id', formValues.category_id);
        formData.append('university_id', formValues.university_id);
        formData.append('introduction', formValues.introduction);
        formData.append('original_cost', formValues.original_cost);
        formData.append('offered_cost', formValues.offered_cost);
        formData.append('credits', formValues.credits);
        formData.append('rating', formValues.rating);

        // Append images to FormData
        if (formValues.course_image1) {
            formData.append('course_image1', formValues.course_image1);
        }
        if (formValues.course_image2) {
            formData.append('course_image2', formValues.course_image2);
        }

        if (isEditMode) {
            formData.append('course_id', editingCourse.course_id);
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer education_consultancy_916',
            },
            body: formData,
        });

        const responseData = await response.json();

        if (responseData.status) {
            fetchCourses();
            toast.success(responseData.message);
            onCancel();
        } else {
            alert('Failed to save the course.');
        }
    };

    if (!visible) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg w-full overflow-auto max-h-[80vh] max-w-4xl mt-5">
                <h2 className="sticky top-0 bg-white text-xl font-bold mb-4 py-4 border-b">{isEditMode ? "Edit Course" : "Upload Course"}</h2>
                <form className='text-left' onSubmit={e => { e.preventDefault(); handleOk(); }}>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Course Name</label>
                        <input name="course_name" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.course_name} onChange={handleChange} />
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Description</label>
                        <textarea name="description" rows="6" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.description} onChange={handleChange}></textarea>
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Introduction</label>
                        <textarea name="introduction" rows="6" className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.introduction} onChange={handleChange}></textarea>
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">University</label>
                        <select name="university_id" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.university_id} onChange={handleUniversityChange}>
                            <option value="" disabled>Select a university</option>
                            {universities.map(university => (
                                <option key={university.university_id} value={university.university_id}>
                                    {university.university_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Category</label>
                        <select name="category_id" required className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.category_id} onChange={handleChange}>
                            <option value="" disabled>Select a category</option>
                            {categories.map(category => (
                                <option key={category.category_id} value={category.category_id}>
                                    {category.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Original Cost</label>
                        <input name="original_cost" required type="number" className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.original_cost} onChange={handleChange} />
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Offered Cost</label>
                        <input name="offered_cost" required type="number" className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.offered_cost} onChange={handleChange} />
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Credits</label>
                        <input name="credits" required type="number" className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.credits} onChange={handleChange} />
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Duration</label>
                        <input name="duration" required type="text" className="mt-1 block w-full border border-gray-300 rounded-md p-2" value={formValues.duration} onChange={handleChange} />
                    </div>
                    <div className="mb-4 px-6 flex">
                        <label className="block text-sm align-baseline font-medium">Rating</label>
                        <div className="flex ml-4">
                            {[1, 2, 3, 4, 5].map(star => (
                                <svg
                                    key={star}
                                    onClick={() => handleRatingChange(star)}
                                    className={`h-6 w-6 cursor-pointer ${formValues.rating >= star ? 'text-yellow-500' : 'text-gray-300'}`}
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 .587l3.668 7.43 8.132 1.177-5.878 5.41 1.39 8.056L12 18.896l-7.312 3.831 1.39-8.056-5.878-5.41 8.132-1.177z" />
                                </svg>
                            ))} 
                        </div>
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Course Image 1</label>
                        <input type="file" accept="image/*" onChange={e => handleUploadChange(e, 1)} className="mt-1 block w-full border border-gray-300 rounded-md p-2" />
                        {imageUrl1 && <img src={imageUrl1} alt="Preview 1" className="mt-2 w-24" />}
                    </div>
                    <div className="mb-4 px-6">
                        <label className="block text-sm font-medium">Course Image 2</label>
                        <input type="file" accept="image/*" onChange={e => handleUploadChange(e, 2)} className="mt-1 block w-full border border-gray-300 rounded-md p-2" />
                        {imageUrl2 && <img src={imageUrl2} alt="Preview 2" className="mt-2 w-24" />}
                    </div>
                    <div className="mb-4 px-6">
                        <label className="inline-flex items-center">
                            <input type="checkbox" name="is_active" checked={formValues.is_active} onChange={handleChange} className="mr-2" />
                            <span className="text-sm">Is Active</span>
                        </label>
                    </div>
                    <div className="bg-white bottom-0 sticky px-6 py-4 flex justify-between">
                        <button type="button" onClick={onCancel} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400">Cancel</button>
                        <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">{isEditMode ? "Update" : "Submit"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CourseModal;
