import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import Cookies from "js-cookie";
import SummaryApi from "../common"; // Adjust the import path as necessary
import { toast } from "react-toastify";
import PageTitle from "../components/PageTitle";

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(""); // Single string for OTP
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true

    try {
      const response = await fetch(SummaryApi.emailVerification.url, {
        method: "POST", // Use POST method
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Send email in request body
      });

      // Check if response is valid JSON
      const responseText = await response.text();
      let responseData = {};
      try {
        responseData = JSON.parse(responseText);
      } catch (err) {
        throw new Error("Response is not valid JSON");
      }

      if (responseData.status) {
        toast.success("OTP sent to your email."); 
        setIsOtpVisible(true);
      } else {
        toast.error("Email not found. Please check your email.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;

    if (/^[0-9]{0,4}$/.test(value)) {
      // Allow only 4 digits
      setOtp(value);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true

    try {
      // Make API call to verify OTP
      const response = await fetch(SummaryApi.otpVerification.url, {
        method: "POST", // Adjusted to use POST as per the PHP backend
        headers: {
          Authorization: "Bearer education_consultancy_916",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }), // Send email and OTP in request body
      });

      if (!response.ok) {
        throw new Error("Failed to verify OTP");
      }

      const responseData = await response.json();
      if (responseData.status) {
        toast.success(responseData.message);
        Cookies.set('G3T8B2WJ4L9ZK7XYA5P1', btoa(responseData.student_id), { expires: 365 });
        navigate("/"); // Redirect after successful login
      } else {
        toast.error("Invalid OTP, please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Failed to verify OTP");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageTitle title="Please Login Here" pageName="Login" />
      <div className="flex items-center justify-center pt-20 pb-20">
        <div className="bg-white rounded-lg shadow-md p-8">
          <div className="flex justify-center mb-4">
            <div className="bg-gray-200 rounded-full p-4">
              <span className="text-[#853372] text-3xl">
                <CgProfile />
              </span>
            </div>
          </div>
          <h2 className="text-left text-lg font-semibold">
            {isOtpVisible ? "OTP" : "Email"}
          </h2>
          <form onSubmit={isOtpVisible ? handleOtpSubmit : handleEmailSubmit}>
            {!isOtpVisible ? (
              <>
                <input
                  type="email"
                  placeholder="Enter email"
                  className="w-full p-2 border border-gray-300 rounded mb-4"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className={`w-full text-white py-2 rounded transition ${
                    loading ? "bg-gray-400" : "bg-[#853372] hover:bg-[#a94a92]"
                  }`}
                  disabled={loading} // Disable button if loading
                >
                  {loading ? "Sending..." : "Next"}
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="w-full text-left p-2 border border-gray-300 rounded mb-4"
                  value={otp}
                  onChange={handleOtpChange}
                  maxLength={4} // Limit to 4 digits
                  required
                />
                <button
                  type="submit"
                  className={`w-full text-white py-2 rounded transition ${
                    loading ? "bg-gray-400" : "bg-[#853372] hover:bg-[#a94a92]"
                  }`}
                  disabled={loading} // Disable button if loading
                >
                  {loading ? "Verifying..." : "Verify OTP"}
                </button>
              </>
            )}
          </form>
          <p className="text-center mt-4">
            Don't have an account?{" "}
            <Link to="/signup">
              <span className="text-[#853372] cursor-pointer hover:underline">
                Sign up
              </span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
