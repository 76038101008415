import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import PageTitle from "../components/PageTitle";
import { FaUpload } from "react-icons/fa";
import SummaryApi from "../common";
import { toast } from "react-toastify";

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({
    full_name: '',
    email: '',
    mobile_number: '',
    address: '',
    city: '',
    student_image: ''
  });
  const [imageFile, setImageFile] = useState(null);

  const encodedStudentId = Cookies.get('G3T8B2WJ4L9ZK7XYA5P1');
  const studentId = encodedStudentId ? atob(encodedStudentId) : null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchStudentDetails = async () => {
    if (studentId) {
      try {
        const response = await fetch(SummaryApi.getStudentDetails.url, {
          method: SummaryApi.getStudentDetails.method,
          headers: {
            'Authorization': 'Bearer education_consultancy_916',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ student_id: studentId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch student details');
        }

        const responseData = await response.json();
        setData({
          full_name: responseData?.data?.full_name || '',
          email: responseData?.data?.email || '',
          mobile_number: responseData?.data?.mobile_number || '',
          address: responseData?.data?.address || '',
          city: responseData?.data?.city || '',
          student_image: responseData?.data?.student_image || ''
        });
      } catch (error) {
        console.error('Error fetching student details:', error);
      }
    }
  };

  useEffect(() => {
    fetchStudentDetails();
  }, [studentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSave = async () => {
    if (!studentId) return;

    try {
      const formData = new FormData();
      formData.append('student_id', studentId);
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });
      if (imageFile) {
        formData.append('student_image', imageFile);
      }

      const response = await fetch(SummaryApi.updateStudent.url, {
        method: SummaryApi.updateStudent.method,
        headers: {
          'Authorization': 'Bearer education_consultancy_916',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update student details');
      }

      const updatedData = await response.json();
      if (updatedData.status) {
        toast.success(updatedData.message);
        fetchStudentDetails();
      }
    } catch (error) {
      console.error('Error updating student details:', error);
      alert('Failed to update profile. Please try again.');
    } finally {
      setIsEditing(false);
      setImageFile(null);
    }
  };

  return (
    <div className="p-4">
      <PageTitle title="Your Profile" pageName="Profile" />
      <div className="flex items-center justify-center pt-20 pb-20">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="flex items-center">
            {/* Render selected image if exists, otherwise render existing profile image */}
            {isEditing && imageFile ? (
              <img
                src={URL.createObjectURL(imageFile)}
                alt="Profile Preview"
                className="w-24 h-24 rounded-full object-cover border-2 border-[#853372] mb-4"
              />
            ) : (
              <img
                src={SummaryApi.studentImg.url + data.student_image || 'default-profile.png'}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover border-2 border-[#853372] mb-4"
              />
            )}
            <h2 className="text-2xl ml-6 font-bold text-gray-800">{data.full_name || 'User Name'}</h2>
          </div>

          <div className="grid grid-cols-1 gap-y-4">
            {isEditing ? (
              <>
                {['full_name', 'email', 'mobile_number', 'address', 'city'].map((field, index) => (
                  <div className="flex justify-between items-center" key={index}>
                    <span className="font-semibold text-left text-gray-700 capitalize">{field.replace('_', ' ')}:</span>
                    <input
                      type={field === 'email' ? 'email' : 'text'}
                      name={field}
                      value={data[field]}
                      onChange={handleChange}
                      className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#853372] transition w-3/4"
                      placeholder={`Enter your ${field.replace('_', ' ')}`}
                    />
                  </div>
                ))}
                <div className="mb-4">
                  <label className="block text-left font-medium text-gray-600 p-2" htmlFor="image">Profile Image:</label>
                  <input
                    type="file"
                    id="image"
                    name="student_image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="mt-1 p-2 w-full border border-gray-300 rounded"
                  />
                </div>

                <button
                  onClick={handleSave}
                  className="bg-[#853372] text-white rounded p-2 mt-4 hover:bg-[#9b2e82] transition"
                >
                  Save Changes
                </button>
              </>
            ) : (
              <>
                {[ 'email', 'mobile_number', 'address', 'city'].map((field, index) => (
                  <div className="flex justify-between items-center" key={index}>
                    <span className="font-semibold text-gray-700 capitalize">{field.replace('_', ' ')}:</span>
                    <span className="text-gray-600">{data[field]}</span>
                  </div>
                ))}
                <button
                  onClick={() => setIsEditing(true)}
                  className="bg-[#853372] text-white rounded p-2 mt-4 hover:bg-[#9b2e82] transition"
                >
                  Edit Profile
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
