import React from 'react'
import PageTitle from '../components/PageTitle'
import PaginatedCourses from '../components/PaginatedCourses'

const Course = () => {
  const currentUniversityId = 1;
  return (
    <div>
        <PageTitle title="Have a look latest Course here around you" pageName="Course" />
        <PaginatedCourses universityId={currentUniversityId}/>
    </div>
  )
}

export default Course