import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import CourseInfo from '../components/CourseInfo';
import { decodeId } from '../helpers/idEncoder';
import SummaryApi from '../common';

const CourseDetail = () => {
    const { id } = useParams(); // Get the encoded course ID from the URL
    const decodedId = decodeId(id); // Decode the ID
    const [course, setCourse] = useState(null); // State for course details
    const [loading, setLoading] = useState(true); // State for loading
    const [error, setError] = useState(null); // State for error handling

    // Fetch course details by ID
    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const response = await fetch(`${SummaryApi.getAllCourses.url}`, {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer education_consultancy_916',
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    // Find the course matching the decoded ID
                    const foundCourse = data.data.find(course => course.course_id === decodedId);
                    setCourse(foundCourse); // Set the found course or null if not found
                } else {
                    throw new Error(data.message || 'Failed to fetch course details.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCourseDetails();
    }, [decodedId]);

    if (loading) {
        return <div>Loading course details...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!course) {
        return <div>Course not found</div>;
    }

    return (
        <div>
            {/* Pass category_name as title and course_name as pageName */}
            <PageTitle title={course.category_name} pageName={course.course_name} /> 
            <CourseInfo course={course} />
        </div>
    );
};

export default CourseDetail;
