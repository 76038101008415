import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import SummaryApi from '../common';

// Set up the modal's accessibility
Modal.setAppElement('#root');

const EnrolledStudents = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(SummaryApi.getAdminStudents.url);
        const data = await response.json();

        if (data.status) {
          setStudents(data.data);
        } else {
          setError("Failed to fetch students");
        }
      } catch (err) {
        setError("An error occurred while fetching students");
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  const openModal = (student) => {
    setSelectedStudent(student);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedStudent(null);
    setModalOpen(false);
  };

  // Filter students based on search term and enrollment count
  const filteredStudents = students.filter(student => {
    const nameMatches = student.full_name.toLowerCase().includes(searchTerm.toLowerCase());
    const mobileMatches = student.mobile_number.includes(searchTerm);
    const hasEnrollments = student.enrollment_count > 0; // Ensure enrollment_count is greater than 0
    return (nameMatches || mobileMatches) && hasEnrollments;
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const daySuffix = (day) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const value = day % 10;
      return suffixes[(value > 3 || Math.floor(day % 100 / 10) === 1) ? 0 : value];
    };
    return `${date.getDate()}${daySuffix(date.getDate())} ${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`;
  };
  

  // Define columns for the table
  const columns = React.useMemo(() => [
    {
      Header: 'Image',
      accessor: 'student_image',
      Cell: ({ value }) => (
        <div className="flex justify-center">
          <img src={SummaryApi.studentImage.url + value} alt="student" className="w-12 h-12 object-cover rounded" />
        </div>
      ),
    },
    {
      Header: 'Name',
      accessor: 'full_name',
      Cell: ({ value }) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      Header: 'Mobile',
      accessor: 'mobile_number',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Enrollment Count',
      accessor: 'enrollment_count',
      Cell: ({ row }) => (
        <button
          onClick={() => navigate(`/admin-panel/student/${btoa(row.original.student_id)}`)} // Navigate to specific student ID
          className="text-blue-600 hover:underline"
        >
          {row.original.enrollment_count}
        </button>
      ),
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <button
          onClick={() => openModal(row.original)}
          className="text-blue-600 hover:underline"
        >
          View More
        </button>
      ),
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredStudents });

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-left">Enrolled Students</h1>

      {/* Search Input */}
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Search by name or mobile..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 p-2 rounded w-full"
        />
        <button
          onClick={() => setSearchTerm('')} // Clear the search term
          className="ml-2 bg-gray-500 text-white px-4 rounded hover:bg-gray-600"
        >
          <span className='font-semibold'>Clear</span>
        </button>
      </div>

      <table {...getTableProps()} className="min-w-full border border-gray-300">
        <thead className="bg-gray-200">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="border-b p-2 text-center">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b">
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className="p-2 text-center">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          {selectedStudent && (
            <>
              <div className='flex items-center mb-4'>
                <img src={SummaryApi.studentImage.url + selectedStudent.student_image} alt={selectedStudent.full_name} className="w-32 h-32 object-cover rounded-full" />
                <h2 className="text-2xl ml-4 font-semibold capitalize">{selectedStudent.full_name}</h2>
              </div>
              <div className="flex flex-col">
                <div className="flex mb-2">
                  <strong className="flex-shrink-0 w-32">Mobile:</strong>
                  <span className="ml-2 flex-grow">{selectedStudent.mobile_number}</span>
                </div>
                <div className="flex mb-2">
                  <strong className="flex-shrink-0 w-32">Email:</strong>
                  <span className="ml-2 flex-grow">{selectedStudent.email}</span>
                </div>
                <div className="flex mb-2">
                  <strong className="flex-shrink-0 w-32">Address:</strong>
                  <span className="ml-2 flex-grow">{selectedStudent.address}, {selectedStudent.city}</span>
                </div>
                <div className="flex mb-2">
                  <strong className="flex-shrink-0 w-32">Created Date:</strong>
                  <span className="ml-2 flex-grow">{formatDate(selectedStudent.created_date)}</span>
                </div>
              </div>

              <div className="mt-6">
                <button
                  onClick={closeModal}
                  className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EnrolledStudents;
