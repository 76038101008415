import React from 'react';

const CategoryCard = ({ category, onEdit }) => {
    return (
        <div className="border border-gray-300 p-4 rounded-lg w-full h-full overflow-hidden shadow-md flex flex-col">
            <div className="mb-3">
                <h4 className="text-xl font-semibold text-left">{category.category_name}</h4>
                <p className="text-gray-600 text-left"><strong>University:</strong> {category.university_name}</p>
            </div>
            {/* <div className="flex justify-end">
                <button 
                    onClick={onEdit} 
                    className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
                >
                    Edit
                </button>
            </div> */}
        </div>
    );
};

export default CategoryCard;
