import React from "react";
import './AboutProfile.css'; // Ensure the CSS is linked properly

const AboutProfile = () => {
  return (
    <div className="pgxpm-class bg-white">
      <div className="pgxpm-class-profile">
        {/* Work Experience Section */}
        <div className="age">
          <h2 className="text-4xl font-bold pb-4">WORK EXPERIENCE</h2>
          <div className="age-sec">
            <ul>
              <li>
                <p className="percent text-gray-700 text-lg">11%</p>
                <p className="number">&lt; 10 yrs</p>
              </li>
              <li>
                <p className="percent">61%</p>
                <p className="number">10-15 yrs</p>
              </li>
              <li>
                <p className="percent">14%</p>
                <p className="number">16-20 yrs</p>
              </li>
              <li>
                <p className="percent">14%</p>
                <p className="number">&gt; 20 yrs</p>
              </li>
            </ul>
          </div>
        </div>

        {/* Age Section */}
        <div className="age">
          <h2  className="text-4xl font-bold pb-4">Age</h2>
          <div className="age-sec">
            <ul>
              <li>
                <p className="percent">8%</p>
                <p className="number">&lt; 30 yrs</p>
              </li>
              <li>
                <p className="percent">39%</p>
                <p className="number">30-35 yrs</p>
              </li>
              <li>
                <p className="percent">28%</p>
                <p className="number">36-40 yrs</p>
              </li>
              <li>
                <p className="percent">25%</p>
                <p className="number">&gt; 40 yrs</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutProfile;
