import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";
import { FaUpload } from "react-icons/fa";
import { toast } from 'react-toastify';
import OTPVerification from '../components/OTPVerification';
import SummaryApi from '../common';
import Cookies from 'js-cookie';
import PageTitle from '../components/PageTitle';

const SignUp = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        full_name: '',
        email: '',
        mobile_number: '',
        address: '',
        city: '',
        student_image: null,
    });
    const [showVerification, setShowVerification] = useState(false);
    const [otp, setOtp] = useState();
    const [loading, setLoading] = useState(false); // Loading state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setData((prevData) => ({
                ...prevData,
                student_image: file,
            }));
        }
    };

    const handleSignUpSuccess = (otp) => {
        setOtp(otp);
        setShowVerification(true); // Show the verification modal
    };

    const handleVerificationClose = () => {
        setShowVerification(false); // Close the verification modal
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
    
        try {
            const dataResponse = await fetch(SummaryApi.signUP.url, {
                method: SummaryApi.signUP.method,
                headers: {
                    'Authorization': 'Bearer education_consultancy_916',
                },
                body: formData,
            });
    
            const responseData = await dataResponse.json();
            if (responseData.status) {
                toast.success(responseData.message);
                handleSignUpSuccess(responseData.otp);
            } else {
                toast.error(responseData.message || 'Error occurred during registration');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error occurred during registration');
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleVerificationSuccess = () => {
        setShowVerification(false); 
        navigate('/'); // Navigate to home after successful verification
    };

    return (
        <div>
            <PageTitle title="Please Sign Up Here" pageName="Sign Up" />
            <div className="flex flex-col items-center justify-center pt-20 pb-20 bg-gradient-to-b from-purple-50 to-white">
                <div className="bg-white p-8 rounded shadow-lg w-96">
                    <div className="flex justify-center mb-4">
                        <div className="bg-gray-200 rounded-full p-4">
                            <span className="text-[#853372] text-3xl">
                                <CgProfile />
                            </span>
                        </div>
                    </div>
                    <h2 className="text-center text-2xl font-bold text-gray-700 mb-6">Create Your Account</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-left font-medium text-gray-600" htmlFor="full_name">Full Name:</label>
                            <input
                                type="text"
                                id="full_name"
                                name="full_name"
                                value={data.full_name}
                                onChange={handleChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-red-200 transition"
                                placeholder="Enter your full name"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-left font-medium text-gray-600" htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-red-200 transition"
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-left font-medium text-gray-600" htmlFor="mobile_number">Mobile Number:</label>
                            <input
                                type="tel"
                                id="mobile_number"
                                name="mobile_number"
                                value={data.mobile_number}
                                onChange={handleChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-red-200 transition"
                                placeholder="Enter your mobile number"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-left font-medium text-gray-600" htmlFor="address">Address:</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={data.address}
                                onChange={handleChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-red-200 transition"
                                placeholder="Enter your address"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-left font-medium text-gray-600" htmlFor="city">City:</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={data.city}
                                onChange={handleChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-red-200 transition"
                                placeholder="Enter your city"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-left font-medium text-gray-600" htmlFor="image">Profile Image:</label>
                            <input
                                type="file"
                                id="image"
                                name="student_image"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="mt-1 w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-red-200 transition"
                            />
                        </div>
                        <button
                            type="submit"
                            className={`w-full bg-[#853372] text-white p-2 rounded hover:bg-[#9b2e82] transition ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading} // Disable button when loading
                        >
                            {loading ? 'Signing Up...' : 'Sign Up'} {/* Show loading text */}
                        </button>
                    </form>
                    <p className="text-center mt-4 text-sm text-gray-600">
                        Already have an account? <a href="/login" className="text-[#853372] hover:underline">Login</a>
                    </p>
                </div>

                {/* Render the OTPVerification component as a modal */}
                {showVerification && (
                    <OTPVerification
                        onClose={handleVerificationClose}
                        email={data.email}
                        otp={otp}
                        onSuccess={handleVerificationSuccess} // Pass the success handler
                    />
                )}
            </div>
        </div>
    );
};

export default SignUp;
